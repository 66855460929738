import { useMemo } from 'react';

import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useShallow } from 'zustand/react/shallow';

import { getCases } from '@/api/cases';
import { ENDPOINTS } from '@/api/endpoints';
import { useUserProfile } from '@/states/user-profile';
import { isDevEnv } from '@/libs/dev-logs';

// Infer the type of the parameters of the getCases function
type GetCasesParams = Parameters<typeof getCases>[0];
// Infer the resolved type of the response from the getCases function
type GetCasesResponse = Awaited<ReturnType<typeof getCases>>;

export const useGetCases = () => {
  const profile = useUserProfile(useShallow((s) => s.profile));

  const GET_CASES_PARAMS: GetCasesParams = useMemo(
    () => ({
      user: 'all',
      patient: 'all',
      clinic: profile.clinic && profile.role !== 'vitaziadmin' ? profile.clinic : 'all',
      expand: ['grades'],
    }),
    [profile.clinic, profile.role]
  );

  const { data, error, isLoading, mutate } = useSWR<GetCasesResponse, Error>(
    [ENDPOINTS.CASES, GET_CASES_PARAMS],
    ([, params]) => getCases(params as GetCasesParams),
    { errorRetryCount: 1, revalidateOnFocus: !isDevEnv() }
  );

  const { trigger } = useSWRMutation([ENDPOINTS.CASES, GET_CASES_PARAMS], ([, params]) =>
    getCases(params as GetCasesParams)
  );

  return {
    error,
    cases: data,
    loading: isLoading,
    refetch: mutate,
    refresh: trigger,
  };
};
