import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';

import { Case } from '@/api/cases';
import { ROUTES } from '@/router/routes';
import { useClaimCase } from '@/hooks/grade-flow/use-claim-case';
import { useNavigateFlow } from '@/hooks/grade-flow/use-navigate-flow';
import { useGradeFlow } from '@/states/grade-flow';

import { Button } from '@/components/form/button';
import { Alert } from '@/components/alert';

import { PreviewImages } from './preview-imgs';
import { FooterButtons, LayoutStep } from './layouts-flow';

export function GetStartedStep() {
  const location: { state?: { case?: Case }; pathname: string } = useLocation();

  const { t } = useTranslation();
  const { navigateFlow } = useNavigateFlow();
  const { isClaimedByOtherUser, isClaiming, handleClaimCase } = useClaimCase();

  const { isCaseValidClaimed, claimedAt } = useGradeFlow(
    useShallow((s) => ({
      isCaseValidClaimed: s.isCaseValidClaimed,
      claimedAt: s.claimedAt,
    }))
  );

  const currentCase = useMemo(() => location.state?.case, [location.state?.case]);

  const isStepBlocked = useMemo(() => {
    return isClaiming || !currentCase || isClaimedByOtherUser;
  }, [isClaiming, currentCase, isClaimedByOtherUser]);

  const warningLabel = useMemo(() => {
    if (!currentCase?.claimed_time && !claimedAt) return null;

    const claimedDate = claimedAt ?? new Date(currentCase?.claimed_time ?? '');
    const futureDate = new Date(claimedDate.getTime() + 30 * 60 * 1000); // 30 minutes in the future
    if (futureDate > new Date()) {
      return `You already claimed this case, it will be available for other doctors at: ${futureDate.toLocaleTimeString()}`;
    }

    return null;
  }, [currentCase?.claimed_time, claimedAt]);

  return (
    <LayoutStep>
      <h1 className="text-balance text-5xl font-semibold tracking-tight sm:text-4xl capitalize mb-8">
        {t('grade-flow-get-started-title')}
      </h1>
      {isCaseValidClaimed ? (
        <Alert type="warning" title={t('grade-claimed-case-title')} className="text-left">
          {warningLabel}
        </Alert>
      ) : (
        <p className="max-w-lg text-pretty text-lg font-medium text-gray-500">
          {t('grade-flow-get-started-description')}
        </p>
      )}
      <div className="mt-8">
        <p className="font-bold italic">{`${t('cases-table-case-id')}: ${location.state?.case?.case_id ?? '--'}`}</p>
        <p className="mt-4 text-xs text-pretty text-gray-500">{t('grade-flow-preview-images')}</p>
        <PreviewImages />
      </div>
      <FooterButtons>
        <Link to={ROUTES.GRADING} className="text-sm font-semibold text-gray-900">
          {t('cancel')}
        </Link>
        {isCaseValidClaimed ? (
          <Button onClick={() => navigateFlow('GRADING_CASE_STEP_2')}>{t('continue')}</Button>
        ) : (
          <Button
            onClick={isStepBlocked ? undefined : handleClaimCase}
            loading={isClaiming}
            disabled={isStepBlocked}
          >
            {t('grade-flow-claim-case')}
          </Button>
        )}
      </FooterButtons>
    </LayoutStep>
  );
}
