import { useMemo } from 'react';

import useSWRMutation from 'swr/mutation';
import { useShallow } from 'zustand/react/shallow';

import { getCases } from '@/api/cases';
import { ENDPOINTS } from '@/api/endpoints';
import { useUserProfile } from '@/states/user-profile';

// Infer the type of the parameters of the getCases function
type GetCasesParams = Parameters<typeof getCases>[0];

export const useGetCasesRefresh = () => {
  const profile = useUserProfile(useShallow((s) => s.profile));

  const GET_CASES_PARAMS: GetCasesParams = useMemo(
    () => ({
      user: 'all',
      patient: 'all',
      clinic: profile.clinic && profile.role !== 'vitaziadmin' ? profile.clinic : 'all',
      expand: ['grades'],
    }),
    [profile.clinic, profile.role]
  );

  const { trigger, isMutating } = useSWRMutation(
    [ENDPOINTS.CASES, GET_CASES_PARAMS],
    ([, params]) => getCases(params as GetCasesParams)
  );

  return {
    loading: isMutating,
    refresh: trigger,
  };
};
