import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { EYE_MAP, useGradeFlow } from '@/states/grade-flow';

import { DecisionButtons } from '@/components/form/decision-buttons';
import { MultiDecisionButtons } from '@/components/form/multi-decision-buttons';

import { PearEyeDeepQuestions } from './pear-eye-deep-questions';

type PearEyeQuestionsProps = {
  laterality: 'left' | 'right' | 'both';
};

const LATERALITY_STYLE = {
  left: 'justify-start items-start',
  right: 'justify-end items-start',
  both: 'justify-start items-start',
};

/** We expect to grade pear eye, so here we are grouping the questions */
export function PearEyeQuestions(props: PearEyeQuestionsProps) {
  const { laterality } = props;

  const { t } = useTranslation();

  const OPTIONS_SCALE = useMemo(
    () => [
      { label: t('grade-options-drgrade-scale.none'), value: 'none' },
      { label: t('grade-options-drgrade-scale.mild'), value: 'mild' },
      { label: t('grade-options-drgrade-scale.moderate'), value: 'moderate' },
      { label: t('grade-options-drgrade-scale.severe'), value: 'severe' },
      { label: t('grade-options-drgrade-scale.proliferative'), value: 'proliferative' },
    ],
    [t]
  );

  const { setQuestionEvidenceDme, setQuestionDrGrade, form, isReadable } = useGradeFlow(
    useShallow((s) => ({
      isReadable: s.form.is_readable,
      form: s.form[EYE_MAP[laterality]],
      setQuestionEvidenceDme: s.setQuestionEvidenceDme,
      setQuestionDrGrade: s.setQuestionDrGrade,
    }))
  );

  const handleSelectEvidenceDme = useCallback(
    (select: 'yes' | 'no') => {
      setQuestionEvidenceDme(select === 'yes', laterality);
    },
    [laterality, setQuestionEvidenceDme]
  );

  const handleSelectDrGrade = useCallback(
    (selected: string) => {
      setQuestionDrGrade(selected, laterality);
    },
    [laterality, setQuestionDrGrade]
  );

  return (
    <section className={`flex flex-col ${LATERALITY_STYLE[laterality]}`}>
      <DecisionButtons
        align="left"
        disabled={!isReadable}
        label={t('grade-question-evidence-of-edema')}
        selected={
          form?.evidence_of_dme !== undefined ? (form.evidence_of_dme ? 'yes' : 'no') : undefined
        }
        onSelection={handleSelectEvidenceDme}
      />
      <MultiDecisionButtons
        align="left"
        disabled={!isReadable}
        label={t('grade-question-drgrade-scale')}
        onSelection={handleSelectDrGrade}
        selected={form?.dr_grade_scale ? [form.dr_grade_scale] : []}
        options={OPTIONS_SCALE}
        helper={{
          text: t('grade-question-drgrade-scale-helper'),
          link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8297841/',
        }}
      />
      <PearEyeDeepQuestions laterality={laterality} />
    </section>
  );
}
