import { errorlog } from '@/libs/error-logs';
import { toast_error, toast_success } from '@/libs/toast-wrappers';
import { ERROR_GET_CLINICS, SUCCESS_ELIMINATE_CLINIC } from '@/utils/messages';

import { ENDPOINTS } from './endpoints';
import { GET } from './base';
import { PATCH } from './base/base-request';

type GetClinicsParams = {
  clinic_id?: string;
  limit?: number;
};

type EliminateClinicParams = Pick<GetClinicsParams, 'clinic_id'>;

export type Clinic = {
  contact_phone?: string;
  contact_url?: string;
  clinic_id?: string;
  date_joined?: string;
  province_or_state?: string;
  street_address?: string;
  country?: string;
  postal_code?: string;
  city?: string;
  active?: boolean;
  clinic_name?: string;
  description?: string;
};

type ClinicResponse = {
  data: Clinic[];
};

type ClinicByIdResponse = {
  data: Clinic;
};

export async function getClinics(params?: GetClinicsParams): Promise<Clinic[]> {
  try {
    const query = {
      clinic_id: params?.clinic_id ?? 'all',
      limit: encodeURIComponent(params?.limit ?? 100),
      active_only: 'true',
    };

    const response = await GET<ClinicResponse>(ENDPOINTS.CLINICS, {
      query,
    });

    return response.data;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/fetchClinics', 'Error fetching clinics');
    toast_error(ERROR_GET_CLINICS);
    throw error;
  }
}

export async function getClinicById(params?: GetClinicsParams): Promise<Clinic> {
  try {
    const query = {
      clinic_id: params?.clinic_id?.toString() ?? 'all',
      limit: encodeURIComponent(params?.limit ?? 100),
    };

    const response = await GET<ClinicByIdResponse>(ENDPOINTS.CLINICS, {
      query,
    });

    return response.data;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/getSites', 'Error fetching sites');
    toast_error(ERROR_GET_CLINICS);
    return Promise.reject(error);
  }
}

export async function eliminateClinic(params?: EliminateClinicParams): Promise<void> {
  try {
    const parameters = {
      clinicId: params?.clinic_id ?? '',
    };

    await PATCH(
      ENDPOINTS.ELIMINATE_CLINICS,
      { clinic_id: parameters.clinicId },
      {
        parameters,
      }
    );

    toast_success(SUCCESS_ELIMINATE_CLINIC);
  } catch (error) {
    errorlog(error as Error, 'src/api/clinics/eliminateClinics', 'Error eliminating clinic');
    toast_error(ERROR_GET_CLINICS);
    return Promise.reject(error);
  }
}
