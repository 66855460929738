import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { formatId } from '@/utils/strings';
import { Case } from '@/api/cases';
import { getTelehealthReport } from '@/api/grade';

import { Table } from '../table';
import { DownloadButton } from '../download-button';
import { FetchStates } from '../fetch-states';
import { AcknowledgeState } from './acknowledge-table-state';

type GradesCasesTableProps = {
  item: Case;
};

export function GradesCaseTable(props: GradesCasesTableProps) {
  const { t } = useTranslation();

  const { item: caseItem } = props;
  const { grades } = caseItem;

  const formatTelehealthReport = useCallback(
    (_grade_id: unknown) => {
      if (!_grade_id) return '';
      const grade_id = _grade_id as string;

      const file_name = 'Telehealth_Report - ' + caseItem.patient_id + '.pdf';
      return (
        <DownloadButton
          key={grade_id}
          onDownload={() => getTelehealthReport(grade_id)}
          fileName={file_name}
        />
      );
    },
    [caseItem.patient_id]
  );

  const formatDate = useCallback(
    (date: unknown) => new Date(date as string)?.toLocaleDateString() ?? 'Invalid Date',
    []
  );

  const tooltipGradeId = useCallback(
    (grade: unknown) => (grade as NonNullable<typeof grades>[number]).grade_id,
    []
  );

  const acknowledgeDetails = useCallback(
    (_grade: unknown) => {
      const grade = _grade as NonNullable<typeof grades>[number];
      const needsAcknowledgement = grade.needs_acknowledgement;
      const acknowledgedBy = grade.acknowledged_by;

      return (
        <AcknowledgeState
          needsAcknowledgement={needsAcknowledgement}
          acknowledgedBy={acknowledgedBy}
          patientId={caseItem.patient_id}
          gradeId={grade.grade_id}
          caseId={caseItem.case_id}
        />
      );
    },
    [caseItem.patient_id, caseItem.case_id]
  );

  const HEADERS = [
    {
      label: t('cases-table-grade-id'),
      key: 'grade_id',
      format: formatId,
      tooltip: tooltipGradeId,
    },
    { label: t('cases-table-created'), key: 'createdUtc', format: formatDate },
    { label: t('cases-table-grader'), key: 'user_email' },
    { label: t('cases-table-report'), key: 'grade_id', format: formatTelehealthReport },
    {
      label: t('cases-table-acknowledgement'),
      key: 'acknowledgement',
      type: 'summary' as const,
      value: acknowledgeDetails,
    },
  ];

  return (
    <FetchStates empty={!grades?.length}>
      <Table headers={HEADERS} data={grades ?? []} />
    </FetchStates>
  );
}
