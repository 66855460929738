import { OtherDiseaseKey } from '@/states/grade-flow/types';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

export function useOtherDiseaseLabels() {
  const { t } = useTranslation();

  const DISEASE_OPTIONS_DROPDOWN = useMemo(
    () => [
      {
        label: t('grade-question-other-diseases-options.other-concern-for-glaucoma'),
        value: 'concern_for_glaucoma' as OtherDiseaseKey,
      },
      {
        label: t('grade-question-other-diseases-options.other-macular-drusen'),
        value: 'macular_drusen' as OtherDiseaseKey,
      },
      {
        label: t('grade-question-other-diseases-options.other-macular-pigmentary'),
        value: 'macular_pigmentary_disturbance' as OtherDiseaseKey,
      },
      {
        label: t('grade-question-other-diseases-options.other-epiretinal'),
        value: 'epiretinal_membrane' as OtherDiseaseKey,
      },
      {
        label: t('grade-question-other-diseases-options.other-choroidal'),
        value: 'choroidal_lesion' as OtherDiseaseKey,
      },
    ],
    [t]
  );

  const DISEASE_FOLLOW_UP_OPTIONS = useMemo(
    () => [
      { label: t('grade-options-referral.now'), value: '0_months' },
      { label: t('grade-options-referral.return-1-mo'), value: '1_month' },
      { label: t('grade-options-referral.return-1-to-3-mo'), value: '1-3_months' },
      { label: t('grade-options-referral.return-3-to-6-mo'), value: '3-6_months' },
      { label: t('grade-options-referral.return-6-to-12-mo'), value: '6-12_months' },
      { label: t('grade-options-referral.return-12-to-24-mo'), value: '12-24_months' },
    ],
    [t]
  );

  return { DISEASE_OPTIONS_DROPDOWN, DISEASE_FOLLOW_UP_OPTIONS };
}
