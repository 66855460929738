import { HTMLAttributes, useEffect } from 'react';

import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type OverlayProps = HTMLAttributes<HTMLDivElement> & {
  open: boolean;
  onClose: () => void;
};

export function Overlay(props: OverlayProps) {
  const { open, onClose, className = '', children, ...rest } = props;

  useEffect(() => {
    // block scroll when overlay is open
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  if (!open) return <></>;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLDivElement).classList?.contains('modal-overlay')) {
      onClose();
    }
  };

  return (
    <div
      {...rest}
      className={`modal-overlay fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-80 z-40  ${className}`}
      onClick={handleOverlayClick}
    >
      <div className="fixed top-0 right-0 flex justify-end m-8 z-50">
        <button onClick={onClose} type="button" className="text-white">
          <FontAwesomeIcon icon={faCircleXmark} size="2xl" />
        </button>
      </div>
      <div className="relative max-h-[90vh] max-w-[90vw] m-auto">{children}</div>
    </div>
  );
}
