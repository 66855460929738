import { useCallback, useEffect, useState } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { EYE_MAP, useGradeFlow } from '@/states/grade-flow';
import { PearEyeGrade } from '@/api/grade';

type InitializeLogicProps = {
  laterality: 'left' | 'right' | 'both';
};

/**
 * SUB QUESTION LOGIC
 * This hook is used to initialize the logic for the deep questions
 * We have some validations and logic to disable some fields based on the user selection
 */

export function useInitializeDeepQuestionsLogic({ laterality }: InitializeLogicProps) {
  const [disabledFields, setDisabledFields] = useState<string[]>([]);

  const {
    form,
    setQuestionScaleValue,
    clearProliferativeValues,
    clearSevereValues,
    clearModerateValues,
    clearMildValues,
  } = useGradeFlow(
    useShallow((s) => ({
      form: s.form[EYE_MAP[laterality]],
      setQuestionScaleValue: s.setQuestionScaleValue,
      clearProliferativeValues: s.clearProliferativeValues,
      clearSevereValues: s.clearSevereValues,
      clearModerateValues: s.clearModerateValues,
      clearMildValues: s.clearMildValues,
    }))
  );

  const drgrade = form?.dr_grade_scale;
  const no_retinopathy = form?.scale_10.no_retinopathy;

  const addSevereDefaultDisable = useCallback(() => {
    setDisabledFields((current) => {
      if (current.includes('severe_rhs_in_4_quadrants')) {
        return current;
      }
      return [
        ...current,
        'severe_rhs_in_4_quadrants',
        'moderate_to_severe_irma_in_1_plus_quadrants',
        'venous_beading_in_2_plus_quadrants',
      ];
    });
  }, []);

  // watch for changes in the drgrade_string, se we can clean up the values
  useEffect(() => {
    if (drgrade !== 'none' && no_retinopathy) {
      setQuestionScaleValue(false, { segment: 'scale_10', key: 'no_retinopathy' }, laterality);
    }

    // clean up the values when the user selects a lower grade
    switch (drgrade) {
      case 'none':
        setQuestionScaleValue(true, { segment: 'scale_10', key: 'no_retinopathy' }, laterality);
        clearMildValues(laterality);
        break;
      case 'mild':
        clearModerateValues(laterality);
        break;
      case 'moderate':
        clearSevereValues(laterality);
        break;
      case 'severe':
        addSevereDefaultDisable();
        clearProliferativeValues(laterality);
        break;
      default:
        break;
    }
  }, [
    drgrade,
    no_retinopathy,
    laterality,
    clearMildValues,
    clearModerateValues,
    clearSevereValues,
    clearProliferativeValues,
    setQuestionScaleValue,
    addSevereDefaultDisable,
  ]);

  /**
   * SUB QUESTION LOGIC
   */

  /**
   * 1. If Moderate (43, 47) selection:
   * if select Mild RHs in 4 quadrants OR severe in 1”
   * Disable “Moderate RHs (4 quadrants or 1 severe) + mild IRMA (1 to 3 quadrants)”
   */
  useEffect(() => {
    if (!form?.scale_47.moderate_rhs_plus_mild_irma) {
      setDisabledFields((current) => {
        if (
          form?.scale_43.mild_rhs_in_4_quadrants_or_severe_in_1 &&
          !current.includes('moderate_rhs_plus_mild_irma')
        ) {
          return [...current, 'moderate_rhs_plus_mild_irma'];
        }
        // remove the field from the disabledFields
        return current.filter((field) => field !== 'moderate_rhs_plus_mild_irma');
      });
    }
    // if moderateRhsPlusMildIrma_bool and mildRhsIn4Quadrants_bool is unchecked then uncheck moderateRhsPlusMildIrma_bool
    if (
      form?.scale_47.moderate_rhs_plus_mild_irma &&
      !form?.scale_43.mild_rhs_in_4_quadrants_or_severe_in_1
    ) {
      setQuestionScaleValue(
        false,
        { segment: 'scale_47', key: 'moderate_rhs_plus_mild_irma' },
        laterality
      );
    }
  }, [form?.scale_43.mild_rhs_in_4_quadrants_or_severe_in_1]);

  /**
   * 1.1
   * if select “Moderate RHs (4 quadrants or 1 severe) + mild IRMA (1 to 3 quadrants)”
   * Then auto select Mild RH
   */
  useEffect(() => {
    if (form?.scale_47.moderate_rhs_plus_mild_irma) {
      setQuestionScaleValue(
        true,
        { segment: 'scale_43', key: 'mild_rhs_in_4_quadrants_or_severe_in_1' },
        laterality
      );
    }
  }, [form?.scale_47.moderate_rhs_plus_mild_irma]);

  /**
   * 1.2
   * if select “Mild IRMA in 1 to 3 quadrants”
   * disable “Mild IRMA 4 quadrants”
   */
  useEffect(() => {
    // if not mildIrmaIn4Quadrants_bool then add it to the disabledFields
    if (!form?.scale_47.mild_irma_in_4_quadrants) {
      setDisabledFields((current) => {
        if (
          form?.scale_43.mild_irma_in_1_to_3_quadrants &&
          !current.includes('mild_irma_in_4_quadrants')
        ) {
          return [...current, 'mild_irma_in_4_quadrants'];
        }
        return current.filter((field) => field !== 'mild_irma_in_4_quadrants');
      });
    }
    // if mild_irma_in_4_quadrants and mild_irma_in_1_to_3_quadrants is unchecked then uncheck mild_irma_in_4_quadrants
    if (form?.scale_47.mild_irma_in_4_quadrants && !form?.scale_43.mild_irma_in_1_to_3_quadrants) {
      setQuestionScaleValue(
        false,
        { segment: 'scale_47', key: 'mild_irma_in_4_quadrants' },
        laterality
      );
    }
  }, [form?.scale_43.mild_irma_in_1_to_3_quadrants]);

  /**
   * 1.3
   * Or, if select “Mild IRMA 4 quadrants”
   * Then auto select “Mild IRMA in 1 to 3 quadrants”
   */
  useEffect(() => {
    if (form?.scale_47.mild_irma_in_4_quadrants) {
      setQuestionScaleValue(
        true,
        { segment: 'scale_43', key: 'mild_irma_in_1_to_3_quadrants' },
        laterality
      );
    }
  }, [form?.scale_47.mild_irma_in_4_quadrants]);

  /**
   * 2 .If Severe (53) selection:
   * Disable “Severe RH in 4 quadrants” AND “Moderate to severe IRMA in 1+ quadrant” AND “Venous Beading in 2+ quadrants” to start with conditional logic.
   * Allow “2 of the above criteria from 47 to remain as is, but if checked, then the remaining 3 options above become available
   */
  useEffect(() => {
    const criteriaFrom47List = [
      'moderate_rhs_plus_mild_irma',
      'mild_irma_in_4_quadrants',
      'severe_rhs_in_2_to_3_quadrants',
      'venous_beading_in_1_quadrant',
    ];

    const criteriaFrom47Selected = criteriaFrom47List.filter(
      (field) => form?.scale_47[field as keyof PearEyeGrade['scale_47']]
    ).length;

    if (criteriaFrom47Selected > 1) {
      setDisabledFields((current) => {
        return current.filter(
          (field) =>
            field !== 'severe_rhs_in_4_quadrants' &&
            field !== 'moderate_to_severe_irma_in_1_plus_quadrants' &&
            field !== 'venous_beading_in_2_plus_quadrants'
        );
      });
    } else {
      addSevereDefaultDisable();
    }
  }, [
    form?.scale_47.moderate_rhs_plus_mild_irma,
    form?.scale_47.mild_irma_in_4_quadrants,
    form?.scale_47.severe_rhs_in_2_to_3_quadrants,
    form?.scale_47.venous_beading_in_1_quadrant,
  ]);

  /**
   * 2.1
   * If select “Severe RH in 4 quadrants” then auto select “Severe RH in 2-3 quadrants”.
   */
  useEffect(() => {
    if (form?.scale_53.severe_rhs_in_4_quadrants) {
      setQuestionScaleValue(
        true,
        { segment: 'scale_47', key: 'severe_rhs_in_2_to_3_quadrants' },
        laterality
      );
    }
  }, [form?.scale_53.severe_rhs_in_4_quadrants]);

  /**
   * 2.2
   * If select “Moderate to severe IRMA in 1+ quadrant” then auto select “Mild IRMA in 4 quadrants.”
   */
  useEffect(() => {
    if (form?.scale_53.moderate_to_severe_irma_in_1_plus_quadrants) {
      setQuestionScaleValue(
        true,
        { segment: 'scale_47', key: 'mild_irma_in_4_quadrants' },
        laterality
      );
    }
  }, [form?.scale_53.moderate_to_severe_irma_in_1_plus_quadrants]);

  /**
   * 2.3
   * If select “Venous Beading in 2+ quadrants” then auto select “Venous beading in 1 quadrant.”
   */
  useEffect(() => {
    if (form?.scale_53.venous_beading_in_2_plus_quadrants) {
      setQuestionScaleValue(
        true,
        { segment: 'scale_47', key: 'venous_beading_in_1_quadrant' },
        laterality
      );
    }
  }, [form?.scale_53.venous_beading_in_2_plus_quadrants]);

  /** 3.If Proliferative (61, 65, 71, 75, 81, 85) selection(s):
   * 65 Moderate PDR: If Select EITHER nve_greater_than_half_da_in_1_plus_quadrants_65 OR nvd_less_than_three_quarters OR BOTH
   * then automatically check 61 Mild PDR “NVE > 0.5 DA in 1+ quadrant”
   */
  useEffect(() => {
    if (
      Boolean(form?.scale_65.nve_greater_than_half_da_in_1_plus_quadrants_65) ||
      Boolean(form?.scale_65.nvd_less_than_three_quarters)
    ) {
      setQuestionScaleValue(
        true,
        { segment: 'scale_61', key: 'nve_greater_than_half_da_in_1_plus_quadrants_61' },
        laterality
      );
      setDisabledFields((current) => {
        if (!current.includes('nve_greater_than_half_da_in_1_plus_quadrants_61')) {
          return [...current, 'nve_greater_than_half_da_in_1_plus_quadrants_61'];
        }
        return current;
      });
    } else {
      setDisabledFields((current) => {
        return current.filter(
          (field) => field !== 'nve_greater_than_half_da_in_1_plus_quadrants_61'
        );
      });
    }
  }, [
    form?.scale_65.nve_greater_than_half_da_in_1_plus_quadrants_65,
    form?.scale_65.nvd_less_than_three_quarters,
  ]);

  /** 3.1
   * If select larger_nvd_or_nve_greater_than_or_equal_to_half_da
   * THEN automatically check 65 Moderate PDR both options and 61 MIld PDR
   */
  useEffect(() => {
    if (form?.scale_71_75.larger_nvd_or_nve_greater_than_or_equal_to_half_da) {
      setQuestionScaleValue(
        true,
        { segment: 'scale_65', key: 'nve_greater_than_half_da_in_1_plus_quadrants_65' },
        laterality
      );
      setQuestionScaleValue(
        true,
        { segment: 'scale_65', key: 'nvd_less_than_three_quarters' },
        laterality
      );
      setQuestionScaleValue(
        true,
        { segment: 'scale_61', key: 'nve_greater_than_half_da_in_1_plus_quadrants_61' },
        laterality
      );
      setDisabledFields((current) => {
        if (!current.includes('nve_greater_than_half_da_in_1_plus_quadrants_65')) {
          return [...current, 'nve_greater_than_half_da_in_1_plus_quadrants_65'];
        }
        return current;
      });
      setDisabledFields((current) => {
        if (!current.includes('nvd_less_than_three_quarters')) {
          return [...current, 'nvd_less_than_three_quarters'];
        }
        return current;
      });
      setDisabledFields((current) => {
        if (!current.includes('nve_greater_than_half_da_in_1_plus_quadrants_61')) {
          return [...current, 'nve_greater_than_half_da_in_1_plus_quadrants_61'];
        }
        return current;
      });
    } else {
      setDisabledFields((current) => {
        return current.filter(
          (field) =>
            field !== 'nve_greater_than_half_da_in_1_plus_quadrants_65' &&
            field !== 'nvd_less_than_three_quarters' &&
            field !== 'nve_greater_than_half_da_in_1_plus_quadrants_61'
        );
      });
    }
  }, [form?.scale_71_75.larger_nvd_or_nve_greater_than_or_equal_to_half_da]);

  return { disabledFields };
}
