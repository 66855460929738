import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useNavigation } from 'react-router-dom';

import { useShallow } from 'zustand/react/shallow';

import { Case } from '@/api/cases';
import { getLastPathFromUrl } from '@/utils/strings';
import { getRelativeRoute, ROUTES } from '@/router/routes';
import { useGradeFlow } from '@/states/grade-flow';
import { useInitLoadImages } from '@/hooks/grade-flow/use-init-load-images';

import { HeadingGradeFlow } from './heading-flow';
import { GradeResult } from './grade-result';

/**
 * This is the entry point for the grading flow, here we are going initialize the flow or show the grade result
 */
export function GradeFlow() {
  useInitLoadImages();

  const location: { state?: { case?: Case }; pathname: string } = useLocation();
  const navigate = useNavigate();
  const navigation = useNavigation();

  const { reset, initializeFlow } = useGradeFlow(
    useShallow((s) => ({ reset: s.reset, initializeFlow: s.initializeFlow }))
  );

  const currentGradeCase = useMemo(() => {
    if (location.state?.case) {
      const selectedCase = location.state?.case;
      if (!selectedCase?.grades) return undefined;

      return selectedCase.grades.sort((a, b) => {
        // from createdUtc = "2024-07-17 18:50:57.981410+00:00"
        return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
      })[0];
    }
  }, [location.state?.case]);

  useEffect(() => {
    if (navigation.state === 'loading') return;

    // adds default data from case
    const currentCase = location.state?.case;
    const currentGrade = currentCase?.grades?.sort((a, b) => {
      return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
    })[0];

    if (!currentCase) {
      navigate(ROUTES.GRADING);
      return;
    }

    initializeFlow(currentCase);

    const getStartedRoute = getLastPathFromUrl(ROUTES.GRADING_CASE_STEP_1);
    if (!currentGrade && !location.pathname.includes(getStartedRoute)) {
      const getStartedRelative = getRelativeRoute('GRADING_CASE_STEP_1');
      navigate(getStartedRelative, { state: { case: currentCase } });
    }

    // cleans the state of the flow if the user navigates away
    return () => reset();
  }, [navigation.state, navigate, initializeFlow, reset]);

  if (currentGradeCase) {
    return <GradeResult />;
  }

  return (
    <>
      <HeadingGradeFlow />
      <Outlet />
    </>
  );
}
