import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LoadingDots } from './loading-dots';

type FetchStatesProps = {
  loading?: boolean;
  error?: string;
  children?: React.ReactNode;
  empty?: boolean;
  reloadOnError?: boolean;
  refetch?: () => void;
};

export const FetchStates = (props: FetchStatesProps) => {
  const { error, loading, children, empty = false, reloadOnError, refetch } = props;
  const navigate = useNavigate();

  const handleReload = useCallback(() => {
    navigate(0);
  }, [navigate]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (loading) {
    return (
      <section className="flex-full-center">
        <LoadingDots />
      </section>
    );
  }

  if (error) {
    return (
      <section className="h-full flex-full-center flex-col">
        <p className="text-base font-semibold text-red-800">Error</p>
        <h3 className=" text-balance text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          {error}
        </h3>
        <p className="mt-2 text-pretty text font-medium text-gray-500">
          Sorry, we&apos;re having trouble loading the data.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            type="button"
            onClick={handleGoBack}
            className="text-sm font-semibold text-gray-900"
          >
            Go back
          </button>
          <button
            type="button"
            onClick={reloadOnError ? handleReload : refetch}
            className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary/90"
          >
            Retry
            <span className="ml-2" aria-hidden="true">
              <FontAwesomeIcon icon={faRotateRight} />
            </span>
          </button>
        </div>
      </section>
    );
  }

  if (empty) return <div className="flex-full-center">No data to display</div>;

  return <>{children}</>;
};
