import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useShallow } from 'zustand/react/shallow';

import { Case } from '@/api/cases';
import { getRelativeRoute } from '@/router/routes';
import { useGradeFlow } from '@/states/grade-flow';

type GoBackStepProps = {
  backTo: 'GRADING_CASE_STEP_1' | 'GRADING_CASE_STEP_2' | 'GRADING_CASE_STEP_3';
  label?: string;
};

export function GoBackStep(props: GoBackStepProps) {
  const { backTo, label = 'Go Back' } = props;

  const { isSaving } = useGradeFlow(useShallow((s) => ({ isSaving: s.isSavingGrade })));
  const location: { state?: { case?: Case } } = useLocation();
  const navigate = useNavigate();

  const currentCase = useMemo(() => location.state?.case, [location.state?.case]);

  const handleGoBack = useCallback(() => {
    navigate(`../${getRelativeRoute(backTo)}`, { state: { case: currentCase } });
  }, [currentCase, backTo, navigate]);

  return (
    <button
      type="button"
      onClick={handleGoBack}
      className="text-sm font-semibold text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
      disabled={isSaving}
    >
      {label}
    </button>
  );
}
