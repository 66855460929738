import { useCallback, useMemo } from 'react';

import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';

import { useGradeFlow } from '@/states/grade-flow';
import { useSaveNewGrade } from '@/hooks/grade-flow/use-save-new-grade';

import { FetchStates } from '@/components/fetch-states';
import { Button } from '@/components/form/button';
import { Signature } from '@/components/signature';
import { MultiDecisionButtons } from '@/components/form/multi-decision-buttons';
import { Hr } from '@/components/divider';
import { Alert } from '@/components/alert';

import { FooterButtons, LayoutStep } from './layouts-flow';
import { GradeReadableImages } from './grade-readable-images';
import { GoBackStep } from './go-back-step-btn';
import { OtherDiseaseQuestion } from './other-disease';
import { GradeUrgentFollowUp } from './urgent-follow-up';

export function SignatureStep() {
  const { t } = useTranslation();

  const { onSubmitGradeCase } = useSaveNewGrade();
  const { isLoadingImages, isReadable, isSavingGrade, form, setReferralDrFollowUp, setSignature } =
    useGradeFlow(
      useShallow((s) => ({
        isLoadingImages: s.isLoadingImages,
        isReadable: s.form.is_readable,
        isSavingGrade: s.isSavingGrade,
        setReferralDrFollowUp: s.setReferralDrFollowUp,
        setSignature: s.setSignature,
        form: s.form,
      }))
    );

  const handleSelectFollowUp = useCallback(
    (selected: string) => {
      setReferralDrFollowUp(selected);
    },
    [setReferralDrFollowUp]
  );

  const handleSignatureChange = useCallback(
    (signature: string) => {
      setSignature(signature);
    },
    [setSignature]
  );

  const isSaveBlocked = useMemo(() => {
    return !form?.signature_data_uri || isReadable === undefined;
  }, [form, isReadable]);

  const OPTIONS_REFERRAL = useMemo(
    () => [
      { label: t('grade-options-referral.now'), value: '0_months' },
      { label: t('grade-options-referral.return-6-to-12-mo'), value: '6-12_months' },
      { label: t('grade-options-referral.return-12-mo'), value: '12_months' },
    ],
    [t]
  );

  return (
    <LayoutStep>
      <FetchStates loading={isLoadingImages}>
        <GradeReadableImages size="sm" />
      </FetchStates>
      {isReadable ? (
        <>
          <MultiDecisionButtons
            disabled={isSavingGrade}
            label={t('grade-question-referral')}
            onSelection={handleSelectFollowUp}
            selected={form?.referral_dr_follow_up ? [form?.referral_dr_follow_up] : []}
            options={OPTIONS_REFERRAL}
            helper={{
              text: t('grade-question-referral-helper'),
              link: 'https://grades.vitazi.ai/Diabetic%20Retinopathy%20PPP_2022%20Update.pdf#page=25',
            }}
          />
          <OtherDiseaseQuestion />
          <GradeUrgentFollowUp />

          <Hr className="col-span-2 mt-8" />
        </>
      ) : (
        <Alert type="warning" title={t('grade-error-not-readable')} className="my-8">
          {t('grade-error-not-readable-text')}
        </Alert>
      )}
      <h4 className="text-base/5 font-medium text-gray-800 mt-8 mb-4 text-center">
        {t('grade-question-signature')}
      </h4>
      <Signature
        onChange={handleSignatureChange}
        initialSignature={form?.signature_data_uri}
        disabled={isSavingGrade}
      />

      <FooterButtons>
        <GoBackStep backTo={isReadable ? 'GRADING_CASE_STEP_3' : 'GRADING_CASE_STEP_2'} />
        <Button onClick={onSubmitGradeCase} disabled={isSaveBlocked} loading={isSavingGrade}>
          {t('Submit')}
        </Button>
      </FooterButtons>
    </LayoutStep>
  );
}
