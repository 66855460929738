import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import { useShallow } from 'zustand/react/shallow';

import { ALERT_CONFIG, swalert } from '@/libs/sweetalert';
import { devlog } from '@/libs/dev-logs';
import { useGradeFlow } from '@/states/grade-flow';

import { DecisionButtons } from '@/components/form/decision-buttons';

export function GradeUrgentFollowUp() {
  const { t } = useTranslation();

  const { form, isDisabled, setUrgentCase } = useGradeFlow(
    useShallow((s) => ({
      form: s.form,
      isDisabled: s.form.is_readable === undefined || s.isSavingGrade,
      setUrgentCase: s.setUrgentCase,
    }))
  );

  const handleSetUrgentCaseFalse = useCallback(() => {
    setUrgentCase(false);
  }, [setUrgentCase]);

  const handleActiveUrgentFollowUp = useCallback(async () => {
    try {
      const result = await swalert.fire({
        ...ALERT_CONFIG,
        title: t('grade-urgency-confirm-title'),
        text: `${t('grade-urgency-confirm-text')} ${t('grade-urgency-tooltip')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel'),
      });
      if (result.isConfirmed) {
        setUrgentCase(true);
      }
    } catch (error) {
      devlog(error);
    }
  }, [t, setUrgentCase]);

  const handleSetUrgentCase = useCallback(
    (selected: string) => {
      if (selected === 'yes') {
        void handleActiveUrgentFollowUp();
      }
      if (selected === 'no') {
        handleSetUrgentCaseFalse();
      }
    },
    [handleActiveUrgentFollowUp, handleSetUrgentCaseFalse]
  );

  const selected =
    form?.is_urgent_case !== undefined ? (form.is_urgent_case ? 'yes' : 'no') : undefined;

  return (
    <DecisionButtons
      disabled={isDisabled}
      onSelection={handleSetUrgentCase}
      selected={selected}
      label={
        <>
          {t('grade-urgency-button')}
          <span className="text-red-500 ml-1" data-tooltip-id="urgent-button-label">
            <FontAwesomeIcon icon={faCircleExclamation} size="lg" />
          </span>
          <Tooltip id="urgent-button-label" place="top">
            {t('grade-urgency-tooltip')}
          </Tooltip>
        </>
      }
    />
  );
}
