import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { OptionButton } from './option-button';

type DecisionButtonsProps = {
  label: ReactNode;
  selected?: 'yes' | 'no';
  onSelection: (value: 'yes' | 'no') => void;
  align?: 'center' | 'left';
  disabled?: boolean;
  helper?: {
    text: string;
    link: string;
  };
};

export function DecisionButtons(props: DecisionButtonsProps) {
  const { label, selected, onSelection, align = 'center', helper, disabled } = props;

  const { t } = useTranslation();

  return (
    <fieldset>
      <label
        className={`text-base/5 font-medium text-gray-800 mt-8 mb-4 ${align === 'center' ? 'text-center' : 'text-left'}`}
      >
        {label}
        {helper ? (
          <a href={helper.link} target="_blank" rel="noopener noreferrer" className="ml-2 text-sm">
            {helper.text}
          </a>
        ) : null}
      </label>
      <div className={`flex gap-2 ${align === 'center' ? 'justify-center' : 'justify-start'}`}>
        <OptionButton
          value="no"
          active={selected === 'no'}
          onClick={() => onSelection('no')}
          disabled={disabled}
        >
          {t('no')}
        </OptionButton>
        <OptionButton
          value="yes"
          active={selected === 'yes'}
          onClick={() => onSelection('yes')}
          disabled={disabled}
        >
          {t('yes')}
        </OptionButton>
      </div>
    </fieldset>
  );
}
