import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';

import { useGradeFlow } from '@/states/grade-flow';
import { useNavigateFlow } from '@/hooks/grade-flow/use-navigate-flow';
import { toast_error } from '@/libs/toast-wrappers';

import { Button } from '@/components/form/button';
import { FetchStates } from '@/components/fetch-states';

import { LateralityQuestions } from './laterality-questions';
import { FooterButtons, LayoutStep } from './layouts-flow';
import { GoBackStep } from './go-back-step-btn';

export function QuestionsStep() {
  const { t } = useTranslation();
  const { navigateFlow } = useNavigateFlow();

  const { isLoading, hasLaterality, left, right, both, isReadable } = useGradeFlow(
    useShallow((s) => ({
      isLoading: s.isLoadingImages,
      isReadable: s.form.is_readable,
      hasLaterality: s.isLateralityPresentOnImgs,
      left: s.form.grade_left_eye,
      right: s.form.grade_right_eye,
      both: s.form.grade_both_eyes,
    }))
  );

  const isBlockedByEvidence =
    (hasLaterality &&
      (!left?.evidence_of_dme === undefined || !right?.evidence_of_dme === undefined)) ||
    (!hasLaterality && !both?.evidence_of_dme === undefined);

  const isBlockedByGrade =
    (hasLaterality && (!left?.dr_grade_scale || !right?.dr_grade_scale)) ||
    (!hasLaterality && !both?.dr_grade_scale);

  const handleContinue = () => {
    if (isBlockedByEvidence) {
      toast_error(t('grade-error-evidence-dme'));
      return;
    }
    if (isBlockedByGrade) {
      toast_error(t('grade-error-dr-grade'));
      return;
    }
    navigateFlow('GRADING_CASE_STEP_4');
  };

  useEffect(() => {
    if (!isReadable) {
      navigateFlow('GRADING_CASE_STEP_2');
    }
  }, [isReadable, navigateFlow]);

  return (
    <LayoutStep>
      <FetchStates loading={isLoading}>
        <LateralityQuestions />
      </FetchStates>
      <FooterButtons>
        <GoBackStep backTo="GRADING_CASE_STEP_2" />
        <Button onClick={handleContinue}>{t('continue')}</Button>
      </FooterButtons>
    </LayoutStep>
  );
}
