/**
 * Convert boolean to string 'Yes' or 'No'
 * @param str expect to be 'Yes' or 'No'
 * @returns
 */
export const decisionStrToBool = (str: string) => {
  return str.toLowerCase() === 'yes';
};

export const decisionBoolToStr = (bool: boolean) => {
  return bool ? 'yes' : 'no';
};

export const formatId = (id: unknown) => {
  if (typeof id === 'string') {
    if (id.length > 5) return id.slice(0, 3) + '..' + id.slice(-5);

    return id;
  }
  return '';
};

/**
 * Extracts the last segment of a URL or path.
 * Handles full URLs, paths, and strings with slashes.
 * @param url - A string representing a URL or path.
 * @returns The last path segment or `undefined` if invalid or empty.
 * @example
 * getLastPathFromUrl('https://example.com/last-path') // 'last-path'
 * getLastPathFromUrl('https://example.com/case/case-id/example') // 'example'
 * getLastPathFromUrl('/case/case-id/example') // 'example'
 * getLastPathFromUrl('/case/') // 'case'
 * getLastPathFromUrl('') // ''
 * getLastPathFromUrl('/') // ''
 */
export const getLastPathFromUrl = (url: string): string => {
  if (!url) return ''; // Handle invalid input.
  return url.replace(/\/+$/, '').split('/').pop() ?? ''; // Trim trailing slashes and split.
};
