import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';

import { useGradeFlow } from '@/states/grade-flow';

import { Badge } from '@/components/badge';

import { PearEyeQuestions } from './pear-eye-questions';
import { GradeReadableImages } from './grade-readable-images';

/** We expect to grade pear eye, so here we are grouping the questions */
export function LateralityQuestions() {
  const { t } = useTranslation();

  const { hasLaterality } = useGradeFlow(
    useShallow((s) => ({ hasLaterality: s.isLateralityPresentOnImgs }))
  );

  if (!hasLaterality) {
    return (
      <div className="flex-1 w-full flex flex-col items-start">
        <GradeReadableImages size="sm" displayTitle={false} />
        <PearEyeQuestions laterality="both" />
      </div>
    );
  }

  return (
    <div className="flex-1 flex w-full content-stretch justify-between">
      <div className="flex-1 pr-4 flex flex-col items-start">
        <Badge color="blue" className="mb-4">
          {t('left')}
        </Badge>
        <GradeReadableImages filter="left" size="sm" displayTitle={false} />
        <PearEyeQuestions laterality="left" />
      </div>
      <div className="w-px bg-gray-200" />
      <div className="flex-1 pl-4 flex flex-col items-start">
        <Badge color="blue" className="mb-4">
          {t('right')}
        </Badge>
        <GradeReadableImages filter="right" size="sm" displayTitle={false} />
        <PearEyeQuestions laterality="right" />
      </div>
    </div>
  );
}
