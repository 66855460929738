import { ButtonHTMLAttributes } from 'react';

import clsx from 'clsx';

type OptionButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  value: string;
  active?: boolean;
  onClick?: (value: string) => void;
  variant?: 'outlined' | 'clean';
};

const STYLES_MAP = {
  outlined: 'border shadow rounded-lg ',
  clean: '',
};

export function OptionButton(props: OptionButtonProps) {
  const {
    active,
    onClick,
    value,
    children,
    className = '',
    variant = 'outlined',
    disabled,
    ...rest
  } = props;

  return (
    <>
      <button
        {...rest}
        type="button"
        disabled={disabled}
        className={clsx(
          className,
          'py-3 pl-2 pr-4 min-w-28 text-sm font-medium transition-colors inline-flex shrink !border-solid ',
          [STYLES_MAP[variant]],
          {
            'justify-start': variant === 'clean',
            'justify-center items-center': variant === 'outlined',
            'cursor-not-allowed opacity-50': disabled,
            'text-white bg-secondary hover:opacity-70 border-secondary': active,
            'text-gray-900 hover:bg-gray-100 border-gray-100': !active,
          }
        )}
        onClick={() => onClick && onClick(value)}
      >
        <span
          className={`block mr-2 h-4 w-4 rounded-full !border transition-colors inline-flex-full-center shrink-0 ${active ? 'bg-white border-white' : 'border-gray-200'}`}
        >
          {active && <span className="block h-2 w-2 bg-secondary rounded-full m-auto shrink-0" />}
        </span>
        <span className="break-words text-left">{children}</span>
      </button>
    </>
  );
}
