import { errorlog } from '@/libs/error-logs';
import { toast_error } from '@/libs/toast-wrappers';

import { POST, GET } from './base/index';
import { ENDPOINTS } from './endpoints';
import { PATCH } from './base/base-request';

export type Grade = {
  _v?: number;
  createdUtc: string;
  case_id: string;
  grade_id: string;
  user_email: string;
  readable_bool: boolean;
  evidenceOfDme_bool: boolean;
  drgrade_string: string;
  referral_string: string;
  otherDiseaseCustom_string: string;
  otherDiseaseFollowUp_string: string;
  otherDiseaseConcernForGlaucoma_bool: boolean;
  otherDiseaseMacularDrusen_bool: boolean;
  otherDiseaseMacularPigmentaryDisturbance_bool: boolean;
  otherDiseaseEpiretinalMembrane_bool: boolean;
  otherDiseaseChoroidalLesion_bool: boolean;
  noRetinopathy_bool: boolean;
  microaneurysms_bool: boolean;
  hardExudates_bool: boolean;
  cws_bool: boolean;
  mildRhs_bool: boolean;
  twoCriteriaFrom47_bool: boolean;
  mildRhsIn4Quadrants_bool: boolean;
  mildIrmaIn1to3Quadrants_bool: boolean;
  moderateRhsPlusMildIrma_bool: boolean;
  mildIrmaIn4Quadrants_bool: boolean;
  severeRhIn2To3Quadrants_bool: boolean;
  venousBeadingIn1Quadrant_bool: boolean;
  severeRhIn4Quadrants_bool: boolean;
  moderateToSevereIrmaIn1PlusQuadrants_bool: boolean;
  venousBeadingIn2PlusQuadrants_bool: boolean;
  nveGreaterThanHalfDaIn1PlusQuadrants_61_bool: boolean;
  nveGreaterThanHalfDaIn1PlusQuadrants_65_bool: boolean;
  nvdLessThanThreeQuarters_bool: boolean;
  largerNvdOrNveGreaterThanOrEqualToHalfDa_bool: boolean;
  viewPartiallyObscuredByVh_bool: boolean;
  signatureDataUri_string: string;
  urgent_bool?: boolean;
  imagesReadable_string?: string;
  needs_acknowledgement?: string;
  acknowledged_by?: string;
};

export type PearEyeGrade = {
  evidence_of_dme?: boolean;
  dr_grade_scale: string;
  scale_10: {
    no_retinopathy: boolean;
  };
  scale_20_35: {
    microaneurysms: boolean;
    hard_exudates: boolean;
    cws: boolean;
    mild_rhs: boolean;
  };
  scale_43: {
    mild_rhs_in_4_quadrants_or_severe_in_1: boolean;
    mild_irma_in_1_to_3_quadrants: boolean;
  };
  scale_47: {
    moderate_rhs_plus_mild_irma: boolean;
    mild_irma_in_4_quadrants: boolean;
    severe_rhs_in_2_to_3_quadrants: boolean;
    venous_beading_in_1_quadrant: boolean;
  };
  scale_53: {
    severe_rhs_in_4_quadrants: boolean;
    moderate_to_severe_irma_in_1_plus_quadrants: boolean;
    venous_beading_in_2_plus_quadrants: boolean;
  };
  scale_61: {
    nve_greater_than_half_da_in_1_plus_quadrants_61: boolean;
  };
  scale_65: {
    nve_greater_than_half_da_in_1_plus_quadrants_65: boolean;
    nvd_less_than_three_quarters: boolean;
  };
  scale_71_75: {
    larger_nvd_or_nve_greater_than_or_equal_to_half_da: boolean;
  };
  scale_81_85: {
    view_partially_obscured_by_vh: boolean;
  };
};

export type GradeV2 = {
  _v: number;
  create_at: string;
  grade_id: string;
  case_id: string;
  user_email: string;
  is_readable: boolean;
  referral_dr_follow_up?: string;
  grade_right_eye?: PearEyeGrade;
  grade_left_eye?: PearEyeGrade;
  grade_both_eyes?: PearEyeGrade;
  other_disease?: {
    custom?: string;
    follow_up?: string;
    concern_for_glaucoma?: boolean;
    macular_drusen?: boolean;
    macular_pigmentary_disturbance?: boolean;
    epiretinal_membrane?: boolean;
    choroidal_lesion?: boolean;
  };
  signature_data_uri?: string;
  is_urgent_case?: boolean;
  images_readable?: { img: string; is_readable: string }[];
  needs_acknowledgement?: string[];
  acknowledged_by?: string;
  acknowledged_at?: string;
};

export type SignGradeV2 = Omit<GradeV2, '_v' | 'create_at' | 'grade_id'>;

type SignGrade = {
  case_id: string;
  user_email: string;
  readable_bool: boolean;
  imagesReadable_string?: string;
  evidenceOfDme_bool?: boolean;
  drgrade_string?: string;
  referral_string?: string;
  otherDiseaseCustom_string?: string;
  noRetinopathy_bool?: boolean;
  microaneurysms_bool?: boolean;
  hardExudates_bool?: boolean;
  cws_bool?: boolean;
  mildRhs_bool?: boolean;
  twoCriteriaFrom47_bool?: boolean;
  mildRhsIn4Quadrants_bool?: boolean;
  mildIrmaIn1to3Quadrants_bool?: boolean;
  moderateRhsPlusMildIrma_bool?: boolean;
  mildIrmaIn4Quadrants_bool?: boolean;
  severeRhIn2To3Quadrants_bool?: boolean;
  venousBeadingIn1Quadrant_bool?: boolean;
  severeRhIn4Quadrants_bool?: boolean;
  moderateToSevereIrmaIn1PlusQuadrants_bool?: boolean;
  venousBeadingIn2PlusQuadrants_bool?: boolean;
  nveGreaterThanHalfDaIn1PlusQuadrants_61_bool?: boolean;
  nveGreaterThanHalfDaIn1PlusQuadrants_65_bool?: boolean;
  nvdLessThanThreeQuarters_bool?: boolean;
  largerNvdOrNveGreaterThanOrEqualToHalfDa_bool?: boolean;
  viewPartiallyObscuredByVh_bool?: boolean;
  signatureDataUri_string: string;
  urgent_bool?: boolean;
  needs_acknowledgement?: string;
};

export async function signGradeCase(grade: SignGrade) {
  try {
    const response = await POST(ENDPOINTS.GRADES, grade);
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/grade/signGradeCase', 'Error grading case');
    toast_error('Error grading case');
    return Promise.reject(error);
  }
}

export async function signGradeCaseV2(grade: SignGradeV2) {
  try {
    const response = await POST(ENDPOINTS.GRADES, { ...grade, _v: 2 });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/grade/signGradeCaseV2', 'Error grading case');
    toast_error('Error grading case');
    return Promise.reject(error);
  }
}

export async function getTelehealthReport(grade_id: string) {
  try {
    const response = await GET<File>(ENDPOINTS.GRADE_TELEHEALTH_REPORT, {
      parameters: { grade_id },
      isFileRequest: true,
    });
    return response;
  } catch (error) {
    errorlog(
      error as Error,
      'src/api/grade/getTelehealthReport',
      'Error getting telehealth report'
    );
    toast_error('Error getting telehealth report');
    return Promise.reject(error);
  }
}

export async function setGradeAcknowledge(
  grade_id: string,
  caseId: string,
  acknowledged_by: string
) {
  try {
    const response = await PATCH(
      ENDPOINTS.GRADE_ACKNOWLEDGE,
      { acknowledged_by, case_id: caseId },
      { parameters: { grade_id } }
    );
    return response;
  } catch (error) {
    errorlog(
      error as Error,
      'src/api/grade/setGradeAcknowledge',
      'Error setting grade acknowledge'
    );
    return Promise.reject(error);
  }
}
