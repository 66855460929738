import { useMemo } from 'react';

import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';

import { useGradeFlow } from '@/states/grade-flow';

export function useIsFlowBlocked() {
  const { t } = useTranslation();

  const { isReadable, imagesReadable } = useGradeFlow(
    useShallow((s) => ({
      isReadable: s.form.is_readable,
      imagesReadable: s.form.images_readable,
    }))
  );

  const isBlockedByReadable = useMemo(() => {
    if (isReadable === undefined) return t('grade-error-please-select-whether-case-is-readable');
    if (imagesReadable?.some((img) => !img.is_readable || img.is_readable === '')) {
      return t('grade-error-please-assign-readability-to-each-image');
    }
    return undefined;
  }, [imagesReadable, isReadable, t]);

  return {
    isBlockedByReadable,
  };
}
