import { errorlog } from '@/libs/error-logs';
import { toast_error, toast_success } from '@/libs/toast-wrappers';
import { ERROR_GET_SITES, SUCCESS_ELIMINATE_SITE } from '@/utils/messages';

import { ENDPOINTS } from './endpoints';
import { GET } from './base';
import { PATCH } from './base/base-request';

type GetSitesParams = {
  clinic_id?: string;
  limit?: number;
};

type EliminateSiteParams = {
  clinic_id: string;
  site_id: string;
};

export type Site = {
  site_id: string;
  clinic_id?: string;
  description?: string;
  active?: boolean;
  timezone?: string;
  province_or_state?: string;
  street_address?: string;
  country?: string;
  postal_code?: string;
  city?: string;
};

type SiteResponse = {
  data: Site[];
};

export async function getSitesByClinic(params?: GetSitesParams): Promise<Site[]> {
  try {
    const query = {
      clinic_id: params?.clinic_id?.toString() ?? 'all',
      limit: encodeURIComponent(params?.limit ?? 100),
    };

    const response = await GET<SiteResponse>(ENDPOINTS.SITES, {
      query,
    });

    return response.data;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/getSites', 'Error fetching sites');
    toast_error(ERROR_GET_SITES);
    return Promise.reject(error);
  }
}

type SiteByIdResponse = {
  data: Site;
};

export async function getSiteById(siteId?: string): Promise<Site> {
  try {
    const response = await GET<SiteByIdResponse>(`${ENDPOINTS.SITE_BY_ID}`, {
      parameters: { siteId: siteId ?? '' },
    });

    return response.data;
  } catch (error) {
    errorlog(error as Error, 'src/api/sites/getSiteById', 'Error fetching site by id');
    toast_error(ERROR_GET_SITES);
    return Promise.reject(error);
  }
}

export async function eliminateSite(params?: EliminateSiteParams): Promise<void> {
  try {
    const parameters = {
      clinicId: params?.clinic_id ?? '',
      siteId: params?.site_id ?? '',
    };

    await PATCH(
      ENDPOINTS.ELIMINATE_SITE,
      { clinic_id: parameters.clinicId },
      {
        parameters,
      }
    );

    toast_success(SUCCESS_ELIMINATE_SITE);
  } catch (error) {
    errorlog(error as Error, 'src/api/clinics/eliminateSites', 'Error eliminating site');
    toast_error(ERROR_GET_SITES);
    return Promise.reject(error);
  }
}
