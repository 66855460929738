import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

export function useDeepQuestionLabels() {
  const { t } = useTranslation();

  const OPTIONS_SCALE = useMemo(
    () =>
      ({
        scale_10: [{ label: t('grade-subquestion-cnone-no-retinopathy'), value: 'no_retinopathy' }],
        scale_20_35: [
          { label: t('grade-subquestion-cmild-microaneurysms'), value: 'microaneurysms' },
          { label: t('grade-subquestion-cmild-hard-exudates'), value: 'hard_exudates' },
          { label: t('grade-subquestion-cmild-cws'), value: 'cws' },
          { label: t('grade-subquestion-cmild-rhs'), value: 'mild_rhs' },
        ],
        scale_43: [
          {
            label: t('grade-subquestion-cmoderate-rhs-in-4-quadrants'),
            value: 'mild_rhs_in_4_quadrants_or_severe_in_1',
          },
          {
            label: t('grade-subquestion-cmoderate-irma-in-1-to-3'),
            value: 'mild_irma_in_1_to_3_quadrants',
          },
        ],
        scale_47: [
          {
            label: t('grade-subquestion-cmoderate-moderate-rhs-plus-irma'),
            value: 'moderate_rhs_plus_mild_irma',
          },
          {
            label: t('grade-subquestion-cmoderate-irma-in-4'),
            value: 'mild_irma_in_4_quadrants',
          },
          {
            label: t('grade-subquestion-cmoderate-severe-rh-in-2-to-3'),
            value: 'severe_rhs_in_2_to_3_quadrants',
          },
          {
            label: t('grade-subquestion-cmoderate-venous-beading'),
            value: 'venous_beading_in_1_quadrant',
          },
        ],
        scale_53: [
          {
            label: t('grade-subquestion-csevere-rh-in-4-quadrants'),
            value: 'severe_rhs_in_4_quadrants',
          },
          {
            label: t('grade-subquestion-csevere-moderate-to-severe-irma'),
            value: 'moderate_to_severe_irma_in_1_plus_quadrants',
          },
          {
            label: t('grade-subquestion-csevere-venous-beading-in-2-plus-quadrants'),
            value: 'venous_beading_in_2_plus_quadrants',
          },
        ],
        scale_61: [
          {
            label: t('grade-subquestion-cproliferative-nve-greater-than-half-in-1-plus-quadrants'),
            value: 'nve_greater_than_half_da_in_1_plus_quadrants_61',
          },
        ],
        scale_65: [
          {
            label: t('grade-subquestion-cproliferative-nve-greater-than-half-in-1-plus-quadrants'),
            value: 'nve_greater_than_half_da_in_1_plus_quadrants_65',
          },
          {
            label: t('grade-subquestion-cproliferative-nvd-lest-than-3-quarters'),
            value: 'nvd_less_than_three_quarters',
          },
        ],
        scale_71_75: [
          {
            label: t('grade-subquestion-cproliferative-larger-nvd-or-nve'),
            value: 'larger_nvd_or_nve_greater_than_or_equal_to_half_da',
          },
        ],
        scale_81_85: [
          {
            label: t('grade-subquestion-cproliferative-view-partially-obscured'),
            value: 'view_partially_obscured_by_vh',
          },
        ],
      }) as const,
    [t]
  );

  return { OPTIONS_SCALE };
}
