import { OptionButton } from './option-button';

type DecisionButtonsProps = {
  label: string;
  options: { value: string; label: string }[];
  selected?: string[];
  onSelection: (value: string) => void;
  align?: 'center' | 'left';
  disabled?: boolean;
  helper?: {
    text: string;
    link: string;
  };
};

export function MultiDecisionButtons(props: DecisionButtonsProps) {
  const { label, selected, onSelection, align = 'center', options, helper, disabled } = props;

  return (
    <fieldset>
      <label
        className={`text-base/5 font-medium text-gray-800 mt-8 mb-4 ${align === 'center' ? 'text-center' : 'text-left'}`}
      >
        {label}
        {helper ? (
          <a href={helper.link} target="_blank" rel="noopener noreferrer" className="ml-2 text-sm">
            {helper.text}
          </a>
        ) : null}
      </label>
      <div
        className={`flex flex-wrap gap-2 ${align === 'center' ? 'justify-center' : 'justify-start'}`}
      >
        {options.map((option) => (
          <OptionButton
            disabled={disabled}
            key={option.value}
            value={option.value}
            active={selected?.includes(option.value)}
            onClick={() => onSelection(option.value)}
          >
            {option.label}
          </OptionButton>
        ))}
      </div>
    </fieldset>
  );
}
