import type { ButtonHTMLAttributes } from 'react';

import clsx from 'clsx';

import { LoadingDots } from '../loading-dots';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

export function Button(props: ButtonProps) {
  const { loading, type = 'button', className, children, disabled, ...rest } = props;

  return (
    <button
      {...rest}
      disabled={disabled ?? loading}
      type={type}
      className={clsx('button', className, { 'opacity-85': disabled ?? loading })}
    >
      {loading ? <LoadingDots /> : children}
    </button>
  );
}
