import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';

import { useGradeFlow } from '@/states/grade-flow';

import { LoadingDots } from '@/components/loading-dots';

export function PreviewImages() {
  const { t } = useTranslation();

  const { imgs = [], isLoading } = useGradeFlow(
    useShallow((state) => ({ imgs: state.images, isLoading: state.isLoadingImages }))
  );

  if (isLoading) {
    return <LoadingDots />;
  }

  if (imgs.length === 0) {
    return <div className="text-gray-500 text-sm">{t('grade-no-image-available')}</div>;
  }

  return (
    <div className="inline-flex gap-2">
      {imgs.map((i, index) => (
        <div key={`grade-image-${index}`} className="h-24">
          <img className="h-20 pointer" src={i.file_uri} alt={`Case img ${index + 1}`} />
        </div>
      ))}
    </div>
  );
}
