import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { EYE_MAP, GradeScaleKey, useGradeFlow } from '@/states/grade-flow';
import { useDeepQuestionLabels } from '@/hooks/grade-flow/use-deep-question-labels';
import { useInitializeDeepQuestionsLogic } from '@/hooks/grade-flow/use-initialize-deep-questions-logic';
import { PearEyeGrade } from '@/api/grade';

import { OptionButton } from '@/components/form/option-button';

import { DeepQuestionSection } from './layouts-flow';

type PearEyeDeepQuestionsProps = {
  laterality: 'left' | 'right' | 'both';
};

const LATERALITY_STYLE = {
  left: 'justify-start items-start text-left',
  right: 'justify-end items-start text-right',
  both: 'justify-start items-star text-left',
};

const SCALE_LEVEL = ['none', 'mild', 'moderate', 'severe', 'proliferative'];

/** We expect to grade pear eye, so here we are grouping the questions */
export function PearEyeDeepQuestions({ laterality }: PearEyeDeepQuestionsProps) {
  const { disabledFields } = useInitializeDeepQuestionsLogic({ laterality });

  const { t } = useTranslation();
  const { OPTIONS_SCALE } = useDeepQuestionLabels();

  const { setQuestionScaleValue, form } = useGradeFlow(
    useShallow((s) => ({
      form: s.form[EYE_MAP[laterality]],
      setQuestionScaleValue: s.setQuestionScaleValue,
    }))
  );
  const drgrade = form?.dr_grade_scale;

  const handleValueScaleChange = useCallback(
    (_value: string, scale: GradeScaleKey) => {
      if (!form) return;

      const key = _value as keyof PearEyeGrade[GradeScaleKey];
      // if selected deselect
      if (form[scale][key]) {
        setQuestionScaleValue(false, { segment: scale, key }, laterality);
        return;
      }
      setQuestionScaleValue(true, { segment: scale, key }, laterality);
    },
    [laterality, form, setQuestionScaleValue]
  );

  if (!form || !drgrade) return null;

  return (
    <section className={`mt-8 mb-24 flex flex-col ${LATERALITY_STYLE[laterality]}`}>
      <h2 className=" text-gray-900 text-lg font-semibold">
        {t('grade-subtitle-question-select')}
      </h2>

      {/* 10 None  */}
      {drgrade === 'none' && (
        <DeepQuestionSection label={t('grade-subtitle-question-none')}>
          <OptionButton
            disabled
            value={OPTIONS_SCALE.scale_10[0].value}
            active={form.scale_10.no_retinopathy}
          >
            {OPTIONS_SCALE.scale_10[0].label}
          </OptionButton>
        </DeepQuestionSection>
      )}

      {/* 20 Very Mild - 35 Mild NPDR */}
      {SCALE_LEVEL.indexOf(drgrade) >= 1 && (
        <DeepQuestionSection label={t('grade-subtitle-question-mild')}>
          {OPTIONS_SCALE.scale_20_35.map((option) => (
            <OptionButton
              key={option.value}
              value={option.value}
              active={form.scale_20_35[option.value]}
              onClick={(value) => handleValueScaleChange(value, 'scale_20_35')}
              disabled={disabledFields.includes(option.value)}
            >
              {option.label}
            </OptionButton>
          ))}
        </DeepQuestionSection>
      )}

      {/* 43 Moderate NPDR -> 47 Moderately Severe NPDR */}
      {SCALE_LEVEL.indexOf(drgrade) >= 2 && (
        <>
          <DeepQuestionSection label={t('grade-subtitle-question-moderate')}>
            {OPTIONS_SCALE.scale_43.map((option) => (
              <OptionButton
                key={option.value}
                value={option.value}
                active={form.scale_43[option.value]}
                onClick={(value) => handleValueScaleChange(value, 'scale_43')}
                disabled={disabledFields.includes(option.value)}
              >
                {option.label}
              </OptionButton>
            ))}
          </DeepQuestionSection>
          <DeepQuestionSection label={t('grade-subtitle-question-moderately-severe')}>
            {OPTIONS_SCALE.scale_47.map((option) => (
              <OptionButton
                key={option.value}
                value={option.value}
                active={form.scale_47[option.value]}
                onClick={(value) => handleValueScaleChange(value, 'scale_47')}
                disabled={disabledFields.includes(option.value)}
              >
                {option.label}
              </OptionButton>
            ))}
          </DeepQuestionSection>
        </>
      )}

      {/* 53 Severe NPDR */}
      {SCALE_LEVEL.indexOf(drgrade) >= 3 && (
        <DeepQuestionSection label={t('grade-subtitle-question-severe')}>
          {/* repeats 47 Moderately Severe NPDR items */}
          {OPTIONS_SCALE.scale_47.map((option) => (
            <OptionButton
              key={option.value}
              value={option.value}
              active={form.scale_47[option.value]}
              onClick={(value) => handleValueScaleChange(value, 'scale_47')}
              disabled={disabledFields.includes(option.value)}
            >
              {option.label}
            </OptionButton>
          ))}
          {/* severe */}
          {OPTIONS_SCALE.scale_53.map((option) => (
            <OptionButton
              key={option.value}
              value={option.value}
              active={form.scale_53[option.value]}
              onClick={(value) => handleValueScaleChange(value, 'scale_53')}
              disabled={disabledFields.includes(option.value)}
            >
              {option.label}
            </OptionButton>
          ))}
        </DeepQuestionSection>
      )}

      {/* 61 Mild PDR -> 81, 85 Advanced PDR */}
      {SCALE_LEVEL.indexOf(drgrade) >= 4 && (
        <>
          <DeepQuestionSection label={t('grade-subtitle-question-mild-pdr')}>
            {OPTIONS_SCALE.scale_61.map((option) => (
              <OptionButton
                key={option.value}
                value={option.value}
                active={form.scale_61[option.value]}
                onClick={(value) => handleValueScaleChange(value, 'scale_61')}
                disabled={disabledFields.includes(option.value)}
              >
                {option.label}
              </OptionButton>
            ))}
          </DeepQuestionSection>

          <DeepQuestionSection label={t('grade-subtitle-question-moderate-pdr')}>
            {OPTIONS_SCALE.scale_65.map((option) => (
              <OptionButton
                key={option.value}
                value={option.value}
                active={form.scale_65[option.value]}
                onClick={(value) => handleValueScaleChange(value, 'scale_65')}
                disabled={disabledFields.includes(option.value)}
              >
                {option.label}
              </OptionButton>
            ))}
          </DeepQuestionSection>

          <DeepQuestionSection label={t('grade-subtitle-question-high-risk-pdr')}>
            {OPTIONS_SCALE.scale_71_75.map((option) => (
              <OptionButton
                key={option.value}
                value={option.value}
                active={form.scale_71_75[option.value]}
                onClick={(value) => handleValueScaleChange(value, 'scale_71_75')}
                disabled={disabledFields.includes(option.value)}
              >
                {option.label}
              </OptionButton>
            ))}
          </DeepQuestionSection>

          <DeepQuestionSection label={t('grade-subtitle-question-advanced-pdr')}>
            {OPTIONS_SCALE.scale_81_85.map((option) => (
              <OptionButton
                key={option.value}
                value={option.value}
                active={form.scale_81_85[option.value]}
                onClick={(value) => handleValueScaleChange(value, 'scale_81_85')}
                disabled={disabledFields.includes(option.value)}
              >
                {option.label}
              </OptionButton>
            ))}
          </DeepQuestionSection>
        </>
      )}
    </section>
  );
}
