import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useNavigateFlow } from '@/hooks/grade-flow/use-navigate-flow';
import { useGradeFlow } from '@/states/grade-flow';
import { useIsFlowBlocked } from '@/hooks/grade-flow/use-is-flow-blocked';
import { toast_error } from '@/libs/toast-wrappers';

import { DecisionButtons } from '@/components/form/decision-buttons';
import { Button } from '@/components/form/button';

import { GradeReadableImages } from './grade-readable-images';
import { GoBackStep } from './go-back-step-btn';
import { FooterButtons, LayoutStep } from './layouts-flow';

export function ReadableImagesStep() {
  const { t } = useTranslation();
  const { navigateFlow } = useNavigateFlow();

  const { isReadable, isLoadingImages, setIsReadable } = useGradeFlow(
    useShallow((s) => ({
      isReadable: s.form.is_readable,
      isLoadingImages: s.isLoadingImages,
      setIsReadable: s.setIsReadable,
    }))
  );

  const { isBlockedByReadable: isStepBlocked } = useIsFlowBlocked();

  const handleContinue = useCallback(() => {
    if (isStepBlocked) {
      toast_error(isStepBlocked);
      return;
    }
    if (isReadable) {
      navigateFlow('GRADING_CASE_STEP_3');
      return;
    }
    // if the case is not readable redirect to the signature step
    navigateFlow('GRADING_CASE_STEP_4');
  }, [navigateFlow, isStepBlocked, isReadable]);

  const handleSelection = useCallback(
    (selection: 'yes' | 'no') => {
      setIsReadable(selection === 'yes');
    },
    [setIsReadable]
  );

  const isReadableOptSelected = useMemo(() => {
    if (isReadable === undefined) return undefined;
    return isReadable ? 'yes' : 'no';
  }, [isReadable]);

  return (
    <LayoutStep>
      <GradeReadableImages />
      <DecisionButtons
        label={t('grade-question-readable-image-quality')}
        selected={isReadableOptSelected}
        onSelection={handleSelection}
      />
      <FooterButtons>
        <GoBackStep backTo="GRADING_CASE_STEP_1" />
        <Button onClick={handleContinue} disabled={isLoadingImages}>
          {t('continue')}
        </Button>
      </FooterButtons>
    </LayoutStep>
  );
}
