import { PearEyeGrade } from '@/api/grade';

export const DEFAULT_STATE = {
  form: {
    other_disease: {
      custom: '',
      follow_up: '',
      concern_for_glaucoma: false,
      macular_drusen: false,
      macular_pigmentary_disturbance: false,
      epiretinal_membrane: false,
      choroidal_lesion: false,
    },
  },
  case: undefined,
  images: [],
  isFlowBlocked: false,
  isCaseValidClaimed: false,
  isLoadingImages: false,
  isLateralityPresentOnImgs: false,
  isOtherDiseaseNoneSelected: false,
  isOtherCustomDiseaseSelected: false,
  isSavingGrade: false,
};

export const DEFAULT_PEAR_EYE_GRADE_FORM: PearEyeGrade = {
  dr_grade_scale: '',
  scale_10: {
    no_retinopathy: false,
  },
  scale_20_35: {
    microaneurysms: false,
    hard_exudates: false,
    cws: false,
    mild_rhs: false,
  },
  scale_43: {
    mild_rhs_in_4_quadrants_or_severe_in_1: false,
    mild_irma_in_1_to_3_quadrants: false,
  },
  scale_47: {
    moderate_rhs_plus_mild_irma: false,
    mild_irma_in_4_quadrants: false,
    severe_rhs_in_2_to_3_quadrants: false,
    venous_beading_in_1_quadrant: false,
  },
  scale_53: {
    severe_rhs_in_4_quadrants: false,
    moderate_to_severe_irma_in_1_plus_quadrants: false,
    venous_beading_in_2_plus_quadrants: false,
  },
  scale_61: {
    nve_greater_than_half_da_in_1_plus_quadrants_61: false,
  },
  scale_65: {
    nve_greater_than_half_da_in_1_plus_quadrants_65: false,
    nvd_less_than_three_quarters: false,
  },
  scale_71_75: {
    larger_nvd_or_nve_greater_than_or_equal_to_half_da: false,
  },
  scale_81_85: {
    view_partially_obscured_by_vh: false,
  },
};

export const EYE_MAP = {
  left: 'grade_right_eye',
  right: 'grade_left_eye',
  both: 'grade_both_eyes',
} as const;
