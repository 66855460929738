import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { ALERT_CONFIG, swalert } from '@/libs/sweetalert';
import { usePatchGradeAcknowledge } from '@/hooks/api/use-patch-grade-acknowledge';
import { useGetCasesRefresh } from '@/hooks/api/use-get-cases-refresh';
import { useUserProfile } from '@/states/user-profile';

import { Badge } from '../badge';

type AcknowledgeStateProps = {
  needsAcknowledgement?: string | string[];
  acknowledgedBy?: string;
  patientId: string;
  gradeId: string;
  caseId: string;
};

export function AcknowledgeState(props: AcknowledgeStateProps) {
  const { needsAcknowledgement, acknowledgedBy, patientId, gradeId, caseId } = props;
  const [acknowledgedByLocal, setAcknowledgedByLocal] = useState<string | undefined>(
    acknowledgedBy
  );

  const { t } = useTranslation();
  const { profile } = useUserProfile(useShallow((s) => ({ profile: s.profile })));
  const { isLoading, patchGradeAcknowledge } = usePatchGradeAcknowledge();
  const { refresh: refreshCasesData } = useGetCasesRefresh();

  const handleOpenAcknowledgeConfirmation = useCallback(async () => {
    const conditions = Array.isArray(needsAcknowledgement)
      ? needsAcknowledgement
      : (JSON.parse(needsAcknowledgement ?? '[]') as string[]);

    const description = t('cases-table-acknowledge-desc-needed')
      .replace('<patiend_id>', patientId)
      .replace('<case_id>', caseId);

    const listConditions = conditions.reduce(
      (prev, curr) => prev + `<li><span>&bull; </span>${curr}</li>`,
      ''
    );

    const result = await swalert.fire({
      ...ALERT_CONFIG,
      title: t('cases-table-needs-acknowledge'),
      html: `<p style="margin-bottom: 1rem;">${description}</p> <ul style="font-style: italic;">${listConditions}</ul>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('yes'),
      cancelButtonText: t('no'),
    });
    if (result.isConfirmed) {
      await patchGradeAcknowledge(gradeId, caseId);
      // If the acknowledge was successful, we update the local state
      // that way the acknowledge badge will be displayed in the meantime the data is refreshed
      setAcknowledgedByLocal(profile.email);
      // Will refresh the global data from the cases
      await refreshCasesData();
    }
  }, [
    t,
    needsAcknowledgement,
    gradeId,
    caseId,
    patientId,
    patchGradeAcknowledge,
    refreshCasesData,
    profile.email,
  ]);

  const handleOpenAcknowledgeResult = useCallback(async () => {
    const conditions = Array.isArray(needsAcknowledgement)
      ? needsAcknowledgement
      : (JSON.parse(needsAcknowledgement ?? '[]') as string[]);

    const description = t('cases-table-acknowledge-desc-result')
      .replace('<user_id>', acknowledgedBy ?? '-')
      .replace('<patient_id>', patientId)
      .replace('<case_id>', caseId);

    const listConditions = conditions.reduce(
      (prev, curr) => prev + `<li><span>&bull; </span>${curr}</li>`,
      ''
    );

    await swalert.fire({
      ...ALERT_CONFIG,
      title: t('cases-table-acknowledge'),
      html: `<p style="margin-bottom: 0.5rem;">${description}</p> <ul>${listConditions}</ul>`,
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: t('okay'),
    });
  }, [t, needsAcknowledgement, acknowledgedBy, patientId, caseId]);

  if (needsAcknowledgement) {
    if (acknowledgedByLocal) {
      return (
        <button type="button" onClick={handleOpenAcknowledgeResult}>
          <Badge variant="outline" color="green">
            {t('cases-table-acknowledged')}
          </Badge>
        </button>
      );
    }
    // needs to acknowledge
    return (
      <button
        type="button"
        onClick={handleOpenAcknowledgeConfirmation}
        disabled={isLoading}
        className={isLoading ? 'cursor-not-allowed' : ''}
      >
        <Badge variant="outline" color="red" isLoading={isLoading}>
          {t('cases-table-acknowledge')}
        </Badge>
      </button>
    );
  }
  return 'NA';
}
