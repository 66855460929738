import { useTranslation } from 'react-i18next';

import { useGetCases } from '@/hooks/api/use-get-cases';

import { FetchStates } from './fetch-states';
import { CasesTable } from './cases/cases-table';
// import { InputField } from './form/input-field';

export function CasesPage() {
  const { t } = useTranslation();
  const { cases, loading, error } = useGetCases();

  return (
    <div className="page-content-wrapper paper">
      <header className="page-header">
        <h1 className="page-title">{t('cases-title')}</h1>
        {/* <div className="search-bar-button-wrapper">
          <InputField name="search" placeholder={t('search')} />
        </div> */}
      </header>
      <FetchStates loading={loading} error={error?.message} reloadOnError>
        <div className="page-content">
          <CasesTable cases={cases ?? []} />
        </div>
      </FetchStates>
    </div>
  );
}
