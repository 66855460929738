import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useShallow } from 'zustand/react/shallow';

import { Case, setCaseClaimed as requestSetCaseClaimed } from '@/api/cases';
import { useUserProfile } from '@/states/user-profile';
import { useGradeFlow } from '@/states/grade-flow';

import { useNavigateFlow } from './use-navigate-flow';

export const useClaimCase = () => {
  const [isClaiming, setIsClaiming] = useState(false);
  const [isClaimedByOtherUser, setIsClaimByOtherUser] = useState(false);

  const profile = useUserProfile((state) => ({ email: state.profile.email }));
  const location: { state?: { case?: Case }; pathname: string } = useLocation();
  const { navigateFlow } = useNavigateFlow();

  const { isCaseValidClaimed, setCaseClaimed, setFlowBlocked } = useGradeFlow(
    useShallow((s) => ({
      isCaseValidClaimed: s.isCaseValidClaimed,
      setCaseClaimed: s.setCaseValidClaimed,
      setFlowBlocked: s.setFlowBlocked,
    }))
  );

  const currentCase = useMemo(() => location.state?.case, [location.state?.case]);

  const handleClaimCase = useCallback(async () => {
    // we are only updating the claimed status when true, we are not unclaiming at this point
    if (!currentCase || !profile.email) return;

    try {
      setIsClaiming(true);
      await requestSetCaseClaimed(currentCase.case_id, profile.email);

      setCaseClaimed(true);
      setIsClaiming(false);

      navigateFlow('GRADING_CASE_STEP_2');
    } catch (error) {
      console.error('Error setting case claimed', error);
      setIsClaiming(false);
    }
  }, [currentCase, navigateFlow, profile.email, setCaseClaimed]);

  // this effect will help to identify if the case is claimed
  useEffect(() => {
    const currentGrade = currentCase?.grades?.sort((a, b) => {
      return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
    })[0];

    // block the step if we don't have a case or if it's already grade
    if (Boolean(currentGrade) || !currentCase) {
      return;
    }

    // if not claimed let the user decide if wants to claim it
    if (!currentCase.claimed_time) {
      return;
    }

    if (isCaseValidClaimed) {
      // the only way to get here is if the user go back to this step, we let the use know that the case is already claimed
      return;
    }

    // if the case is claimed, we need to check if the user is the one that claimed it
    const claimedDate = new Date(currentCase.claimed_time);
    const futureDate = new Date(claimedDate.getTime() + 30 * 60 * 1000); // 30 minutes in the future

    // if the time has expired, let the user claim it, no matter who claimed it
    if (futureDate < new Date()) return;

    // here the claim status is not expired
    // if the user is the one that claimed it, let the user know
    if (currentCase?.claimed_by === profile.email) {
      setCaseClaimed(true);
      navigateFlow('GRADING_CASE_STEP_2');
    } else {
      // if the user did not claim it, let the user know when it will be available
      // for this we are using a banner outside of this component and also disabling the submit button
      setIsClaimByOtherUser(true);
    }
  }, [
    isCaseValidClaimed,
    currentCase,
    navigateFlow,
    profile.email,
    setCaseClaimed,
    setFlowBlocked,
  ]);

  return { isClaimedByOtherUser, isClaiming, handleClaimCase };
};
