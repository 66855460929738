import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { Clinic } from '@/api/clinics';
import { eliminateSite, Site } from '@/api/sites';
import { useGetSitesByClinic } from '@/hooks/api/use-get-sites-by-clinic';
import { formatId } from '@/utils/strings';
import { ALERT_CONFIG, swalert } from '@/libs/sweetalert';

import { Table } from '@/components/table';
import { FetchStates } from '@/components/fetch-states';

type SitesTableProps = {
  item: Clinic;
};

export function ClinicSitesTable({ item }: SitesTableProps) {
  const { sites, loading, refetchSites } = useGetSitesByClinic({ clinic_id: item.clinic_id });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const headers = useMemo(
    () => [
      { key: 'site_id', label: t('site-id'), type: 'value' as const, format: formatId },
      { key: 'description', label: t('site-name') },
      { key: 'timezone', label: t('timezone') },
      { key: 'active', label: t('active'), type: 'status' as const },
      {
        key: 'actions',
        type: 'actions' as const,
        edit: (_site: unknown) => {
          const site = _site as Site;
          navigate(`${site.clinic_id}/site/edit/${site.site_id}`);
        },
        delete: async (_site: unknown) => {
          const site = _site as Site;
          await onEliminateSite(site);
        },
      },
    ],
    []
  );

  const onEliminateSite = async (site: Site) => {
    const result = await swalert.fire({
      ...ALERT_CONFIG,
      title: t('eliminate-site-title'),
      html: `<p style="margin-bottom: 1rem;">${t('eliminate-site-description').replace('{site_name}', `<span style="font-weight: bold">${site.description}</span>` ?? '')}</p>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('yes'),
      cancelButtonText: t('no'),
    });

    if (result.isConfirmed) {
      await eliminateSite({ clinic_id: site.clinic_id ?? '', site_id: site.site_id });
      await refetchSites();
    }
  };

  return (
    <>
      <div className="mb-4 flex justify-end">
        <Link
          onClick={() => console.log('open drawer')}
          to={`${item.clinic_id}/site/new`}
          className="button button-small button-icon link"
        >
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
          {t('new-site-button')}
        </Link>
      </div>
      <FetchStates loading={loading} refetch={refetchSites} empty={!sites?.length}>
        <Table headers={headers} data={sites ?? []} />
      </FetchStates>
    </>
  );
}
