import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

import { Case } from '@/api/cases';
import { parseDateString } from '@/utils/dates';
import { formatId } from '@/utils/strings';

import { Table } from '../table';
import { GradesCaseTable } from './grades-case-table';

type CasesTableProps = {
  cases: Case[];
};

export function CasesTable({ cases }: CasesTableProps) {
  const { t } = useTranslation();

  const formatDate = useCallback(
    (
      date: unknown // format date from '2024-03-14T14:57:21:139969'
    ) => parseDateString(date as string)?.toLocaleDateString() ?? 'Invalid Date',
    []
  );

  const tooltipCaseId = useCallback(
    (case_row: unknown) => (case_row as NonNullable<typeof cases>[number]).case_id,
    []
  );

  const acknowledgeAlert = useCallback(
    (case_row: unknown) => {
      const grades = (case_row as NonNullable<typeof cases>[number]).grades ?? [];
      if (grades.length === 0) return null;

      // we validate if some of the grades needs to acknowledge
      const needsAcknowledgement = grades.some(
        (grade) => grade.needs_acknowledgement && !grade.acknowledged_by
      );

      if (needsAcknowledgement) {
        return (
          <>
            <span data-tooltip-id="acknowledge-alert" data-tooltip-delay-hide={1000}>
              <FontAwesomeIcon icon={faCircleExclamation} className="text-red-700" />
            </span>
            <Tooltip
              id="acknowledge-alert"
              place="top"
              className="!bg-red-50  font-medium !text-red-800"
            >
              {t('cases-table-needs-acknowledge')}
            </Tooltip>
          </>
        );
      }
    },
    [t]
  );

  const shouldHideCollapsible = useCallback((case_row: unknown) => {
    const grades = (case_row as NonNullable<typeof cases>[number]).grades ?? [];
    return grades.length === 0;
  }, []);

  const HEADERS = [
    { label: t('cases-table-case-id'), key: 'case_id', format: formatId, tooltip: tooltipCaseId },
    { label: t('cases-table-patient-id'), key: 'patient_id' },
    { label: t('cases-table-created'), key: 'date_created', format: formatDate },
    {
      label: t('cases-table-report'),
      key: 'grades',
      type: 'collapsible' as const,
      value: acknowledgeAlert,
      hide: shouldHideCollapsible,
    },
  ];

  return <Table headers={HEADERS} data={cases ?? []} CollapsibleComponent={GradesCaseTable} />;
}
