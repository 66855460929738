import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Case } from '@/api/cases';

import { GradeForm } from './legacy-grade-form';
import { GradeFlow } from './grade-flow';

export function GradeCase() {
  const location: { state?: { case?: Case } } = useLocation();

  const currentGradeCase = useMemo(() => {
    if (location.state?.case) {
      const selectedCase = location.state?.case;
      if (!selectedCase?.grades) return undefined;

      return selectedCase.grades.sort((a, b) => {
        // from createdUtc = "2024-07-17 18:50:57.981410+00:00"
        return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
      })[0];
    }
  }, [location.state?.case]);

  if (!currentGradeCase || currentGradeCase._v === 2) {
    return <GradeFlow />;
  }

  return <GradeForm />;
}
