import { useNavigate } from 'react-router-dom';

import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';

import { useGradeFlow } from '@/states/grade-flow';
import { useUserProfile } from '@/states/user-profile';
import { toast_error, toast_success } from '@/libs/toast-wrappers';
import { signGradeCaseV2 } from '@/api/grade';
import { errorlog } from '@/libs/error-logs';
import { ROUTES } from '@/router/routes';

import { useGradeOtherDiseaseLabel } from '../use-grade-other-disease-label';

export function useSaveNewGrade() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getDiseaseLabel } = useGradeOtherDiseaseLabel();

  const { user_email, role } = useUserProfile(
    useShallow((s) => ({ user_email: s.profile.email, role: s.profile.role }))
  );
  const {
    caseId,
    form,
    setIsSavingGrade,
    isOtherDiseaseNoneSelected,
    isOtherCustomDiseaseSelected,
  } = useGradeFlow(
    useShallow((s) => ({
      caseId: s.case?.case_id,
      form: s.form,
      setIsSavingGrade: s.setIsSavingGrade,
      isOtherDiseaseNoneSelected: s.isOtherDiseaseNoneSelected,
      isOtherCustomDiseaseSelected: s.isOtherCustomDiseaseSelected,
    }))
  );

  const onSubmitGradeCase = async () => {
    try {
      if (!caseId || !user_email || form.is_readable === undefined) return;

      // get the signature data
      if (!form.signature_data_uri) {
        toast_error(t('grade-error-missing-signature')); // Please sign the form
        return;
      }

      const { is_readable, images_readable } = form;

      // validate that the user has set the readable status in each image
      if (images_readable?.some((img) => img.is_readable === undefined)) {
        toast_error(t('grade-error-please-assign-readability-to-each-image'));
        setIsSavingGrade(false);
        return;
      }

      // PARTIAL FORM
      // we need to add first validation for readable
      // if is_readable is false, we need to save the form with only the signature
      if (!is_readable) {
        setIsSavingGrade(true);

        await signGradeCaseV2({
          is_readable: false,
          case_id: caseId,
          user_email,
          images_readable,
          signature_data_uri: form.signature_data_uri,
        });

        toast_success(t('grade-success-grade-save'));
        navigate(ROUTES.GRADING);
        setIsSavingGrade(false);
        return;
      }

      // FULL FORM, validations
      // follow up is required
      if (!form.referral_dr_follow_up) {
        toast_error(t('grade-error-missing-follow-up'));
        return;
      }
      // validate incomplete other disease
      if (isOtherCustomDiseaseSelected && !form.other_disease?.custom) {
        toast_error(t('grade-error-missing-other-disease'));
        return;
      }
      // validate at least one option for other-disease is selected
      if (
        !isOtherDiseaseNoneSelected &&
        !isOtherCustomDiseaseSelected &&
        !form.other_disease?.concern_for_glaucoma &&
        !form.other_disease?.macular_drusen &&
        !form.other_disease?.macular_pigmentary_disturbance &&
        !form.other_disease?.epiretinal_membrane &&
        !form.other_disease?.choroidal_lesion
      ) {
        toast_error(t('grade-error-missing-other-disease-option'));
        return;
      }
      // other disease follow up is required, unless the user selects none
      if (!form.other_disease?.follow_up && !isOtherDiseaseNoneSelected) {
        toast_error(t('grade-error-missing-other-disease-follow-up'));
        return;
      }
      if (form.is_urgent_case === undefined) {
        toast_error(t('grade-error-missing-urgent-case'));
        return;
      }

      setIsSavingGrade(true);

      // get acknowledgement status
      const needs_acknowledgement = [];

      if (form.referral_dr_follow_up === '0_months') {
        needs_acknowledgement.push(
          `${t('grade-question-referral')}: ${t('grade-options-referral.now')}`
        );
      }

      if (form.other_disease?.follow_up) {
        // get the other disease follow up list
        const listDiseaseFollowUp = [
          Boolean(form.other_disease?.custom),
          Boolean(form.other_disease?.concern_for_glaucoma),
          Boolean(form.other_disease?.macular_drusen),
          Boolean(form.other_disease?.macular_pigmentary_disturbance),
          Boolean(form.other_disease?.epiretinal_membrane),
          Boolean(form.other_disease?.choroidal_lesion),
        ];
        const isNoneDiseaseSelected = Boolean(isOtherDiseaseNoneSelected);
        const diseaseLabel = getDiseaseLabel(
          listDiseaseFollowUp,
          isNoneDiseaseSelected,
          form.other_disease?.custom
        );

        if (form.other_disease.follow_up === '0_months') {
          needs_acknowledgement.push(
            `${t('grade-acknowledgement-label-follow-up-other-disease')}: ${t('grade-options-referral.now')} (${diseaseLabel})`
          );
        }

        if (form.other_disease.follow_up === '1_month') {
          needs_acknowledgement.push(
            `${t('grade-acknowledgement-label-follow-up-other-disease')}: ${t('grade-options-referral.return-1-mo')} (${diseaseLabel})`
          );
        }

        if (form.other_disease.follow_up === '1-3_months') {
          needs_acknowledgement.push(
            `${t('grade-acknowledgement-label-follow-up-other-disease')}: ${t('grade-options-referral.return-1-to-3-mo')} (${diseaseLabel})`
          );
        }
      }

      if (form.is_urgent_case) {
        needs_acknowledgement.push(t('grade-acknowledgement-label-urgent-follow-up'));
      }

      const isTestGraderRole = role === 'testgrader';
      if (isTestGraderRole) {
        toast_success('Success grading case in test grader mode');
        navigate(ROUTES.GRADING);
        setIsSavingGrade(false);
        return;
      }

      // send the form case to the server
      await signGradeCaseV2({
        ...form,
        is_readable,
        case_id: caseId,
        user_email,
        images_readable,
        needs_acknowledgement: needs_acknowledgement.length ? needs_acknowledgement : undefined,
      });

      toast_success(t('grade-success-grade-save'));
      navigate(ROUTES.GRADING);
    } catch (error) {
      errorlog(error as Error, 'src/hooks/grade-flow/use-save-new-grade', 'Error grading case');
    }
    setIsSavingGrade(false);
  };

  return { onSubmitGradeCase };
}
