export function LayoutStep(props: React.HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <section {...props} className="text-center flex-1 flex-full-center flex-col w-full mt-12" />
  );
}

export function FooterButtons({ children, ...props }: React.HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <footer {...props} className="flex-1 flex justify-center items-end gap-4 mt-8 pb-12">
      <div className="inline-flex-full-center gap-x-6">{children}</div>
    </footer>
  );
}

type DeepQuestionSectionProps = React.HtmlHTMLAttributes<HTMLDivElement> & { label: string };
export function DeepQuestionSection({ label, children, ...props }: DeepQuestionSectionProps) {
  return (
    <>
      <label className="text-base/5 font-medium text-gray-800 mt-8 mb-4">{label}</label>
      <section {...props} className="flex flex-wrap gap-x-4 gap-y-2 mb-2">
        {children}
      </section>
    </>
  );
}
