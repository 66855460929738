import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

export function useGradeOtherDiseaseLabel() {
  const { t } = useTranslation();

  const getDiseaseLabel = useCallback(
    (values: boolean[], isNoneSelected: boolean, custom?: string) => {
      const isAnyOneSelected = values.some((val) => val);

      // if no one was selected yet "default value"
      if (!isAnyOneSelected && !isNoneSelected) {
        return t('default-select');
      }

      // when none selected
      if (isNoneSelected) {
        return t('grade-question-other-diseases-options.none');
      }

      const isOtherSelected = values[0];
      const isOtherSelectedLabel = custom ?? t('grade-question-other-diseases-options.other');

      const otherLabels = [
        '',
        t('grade-question-other-diseases-options.other-concern-for-glaucoma'),
        t('grade-question-other-diseases-options.other-macular-drusen'),
        t('grade-question-other-diseases-options.other-macular-pigmentary'),
        t('grade-question-other-diseases-options.other-epiretinal'),
        t('grade-question-other-diseases-options.other-choroidal'),
      ];

      const isMultiSelected = values.filter((val) => val).length > 1;
      if (isMultiSelected) {
        return `Selected: ${otherLabels
          .filter((_, index) => values[index] && index !== 0)
          .join(', ')} ${isOtherSelected ? `and ${isOtherSelectedLabel.toLowerCase()}` : ''}`;
      }

      if (isOtherSelected) {
        return t('grade-question-other-diseases-options.other');
      }

      return otherLabels[values.indexOf(true)];
    },
    [t]
  );

  return { getDiseaseLabel };
}
