import useSWR from 'swr';

import { ENDPOINTS } from '@/api/endpoints';
import { getSiteById } from '@/api/sites';

export const useGetSiteById = ({ site_id }: { site_id?: string }) => {
  const { data, isLoading, mutate } = useSWR(site_id && [ENDPOINTS.SITE_BY_ID, site_id], () =>
    getSiteById(site_id)
  );

  return {
    loading: isLoading,
    site: data,
    refetchSite: mutate,
  };
};
