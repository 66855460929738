import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Case, getCaseImage } from '@/api/cases';
import { signGradeCase } from '@/api/grade';
import { SignGradeForm, defaultSignGradeForm } from '@/hooks/use-grade-form-logic';
import { useGradeOtherDiseaseLabel } from '@/hooks/use-grade-other-disease-label';
import { useUserProfile } from '@/states/user-profile';
import { decisionStrToBool, decisionBoolToStr } from '@/utils/strings';
import { toast_error, toast_success } from '@/libs/toast-wrappers';
import { errorlog } from '@/libs/error-logs';
import { ROUTES } from '@/router/routes';
import {
  ERROR_MISSING_SIGNATURE,
  SUCCESS_GRADING_CASE,
  ERROR_MISSING_IMAGES_READABLE,
} from '@/utils/messages';

import { Hr } from '@/components/divider';
import { FetchStates } from '@/components/fetch-states';
import { Signature } from '@/components/signature';

import { GradeCaseImages } from './grade-case-images';
import { GradeFormMainQuestions } from './grade-form-main-questions';
import { GradeFormUrgentConfirm } from './grade-form-urgent-confirm';
import { SetCaseClaimed } from './set-case-claimed';

type ImagesList = { path: string; file: File }[];

type LoaderData = { caseImgs: string[] };

export function GradeForm() {
  const [saving, setSaving] = useState(false);
  const [claimedByUser, setClaimedByUser] = useState(false);
  const [isGradeBlocked, setIsGradeBlocked] = useState(false);
  const [images, setImages] = useState<ImagesList>([]);
  const [loadingImages, setLoadingImages] = useState(false);
  const [signature, setSignature] = useState<string | null>(null);
  const [enableImageReadableError, setEnableImageReadableError] = useState<boolean>(false);

  const { register, watch, handleSubmit, setValue, reset } = useForm<SignGradeForm>({
    defaultValues: defaultSignGradeForm,
  });

  const { caseId } = useParams();
  const { email, role } = useUserProfile((state) => ({
    email: state.profile.email ?? '',
    role: state.profile.role,
  }));
  const { caseImgs: imagesUrls } = useLoaderData() as LoaderData;
  const { getDiseaseLabel } = useGradeOtherDiseaseLabel();

  const navigation = useNavigation();
  const navigate = useNavigate();
  const location: { state?: { case?: Case } } = useLocation();
  const { t } = useTranslation();

  const onSignatureChange = useCallback((data: string) => {
    setSignature(data);
  }, []);

  const handleResetForm = useCallback(() => {
    reset();
  }, [reset]);

  const handleSetClaimed = useCallback((_claimed?: boolean) => {
    setClaimedByUser(Boolean(_claimed));
  }, []);

  const currentCase = useMemo(() => {
    if (location.state) {
      return location.state.case;
    }
  }, [location.state]);

  const currentGradeCase = useMemo(() => {
    if (location.state) {
      const selectedCase = location.state?.case;
      if (!selectedCase?.grades) return undefined;

      return selectedCase.grades.sort((a, b) => {
        // from createdUtc = "2024-07-17 18:50:57.981410+00:00"
        return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
      })[0];
    }
  }, [location.state]);

  const disabled = Boolean(currentGradeCase) || saving || isGradeBlocked || !claimedByUser;
  const readableStr = watch('readable_str');
  const isCaseReadable = readableStr === 'yes';

  useEffect(() => {
    if (images.length || loadingImages || !imagesUrls.length) return;

    setLoadingImages(true);
    void (async () => {
      const urls = imagesUrls.filter((url) => !url.includes('.zip'));
      const response = await Promise.all(urls.map(getCaseImage));
      if (response) {
        const images = response.filter((response) => response.file instanceof Blob);
        if (images.length) {
          setImages(images);
          // if there is no current grade we can set the images as unreadable by default
          const readeable = images.map((img) => ({ img: img.path, isReadable: '' }));
          if (!currentGradeCase?.case_id) {
            setValue('imagesReadable_string', JSON.stringify(readeable));
          }
        }
      }
      setLoadingImages(false);
    })();
  }, [imagesUrls.length, loadingImages, setValue, images.length, currentGradeCase?.case_id]);

  // this effect cleans the images when the user navigates back to the grading page
  useEffect(() => {
    if (navigation.state === 'loading' && images.length) {
      setImages([]);
    }
  }, [navigation.state, images]);

  useEffect(() => {
    // preload grade
    if (currentGradeCase) {
      Object.entries(currentGradeCase).forEach(([key, value]) => {
        const originKey = key as keyof SignGradeForm;
        const isBoolean = originKey.includes('bool');
        const formKey = isBoolean
          ? (originKey.replace('bool', 'str') as keyof SignGradeForm)
          : originKey;
        const formValue = isBoolean ? decisionBoolToStr(value as boolean) : value;

        // at this moment the unique number value is the grade from version, so remove it
        if ((formKey as string) === '_v' || typeof formValue === 'number') return;
        setValue(formKey, formValue);
      });
    }
  }, [currentGradeCase?.case_id, setValue]);

  // this effect will help to identify if the case is claimed
  useEffect(() => {
    // if there is a current grade case it doesnt matter the claimed status
    if (currentGradeCase) return;

    // as default claimed is set as false
    // if not claimed let the user decide if wants to claim it
    if (!currentCase?.claimed_time) return;

    const claimedDate = new Date(currentCase.claimed_time);
    const futureDate = new Date(claimedDate.getTime() + 30 * 60 * 1000); // 30 minutes in the future

    // if the time has expired, let the user claim it, no matter who claimed it
    if (futureDate < new Date()) return;

    // here the claim status is not expired
    // if the user is the one that claimed it, let the user know
    if (currentCase?.claimed_by === email) {
      setClaimedByUser(true);
      setIsGradeBlocked(false);
    } else {
      // if the user did not claim it, let the user know when it will be available
      // for this we are using a banner outside of this component and also disabling the submit button
      setClaimedByUser(false);
      setIsGradeBlocked(true);
    }
  }, [currentCase, currentGradeCase, email]);

  const onSubmitGradeCase: SubmitHandler<SignGradeForm> = async (form) => {
    try {
      if (!caseId || !email) return;

      // get the signature data
      if (!signature) {
        toast_error(ERROR_MISSING_SIGNATURE);
        return;
      }

      setSaving(true);

      const {
        readable_str,
        imagesReadable_string,
        evidenceOfDme_str,
        otherDiseaseCustomInput_string,
        otherDiseaseCustom_bool,
        otherDiseaseNone_bool,
        urgent_str,
        ...rest_form
      } = form;

      const imagesReadable = JSON.parse(imagesReadable_string ?? '[]') as {
        img: string;
        isReadable: string;
      }[];

      // validate that the user has set the readable status in each image
      if (imagesReadable.some((img) => !img.isReadable)) {
        toast_error(ERROR_MISSING_IMAGES_READABLE);
        setEnableImageReadableError(true);
        setSaving(false);
        return;
      }

      // we need to add first validation for readable_str
      // if readable_bool is false, we need to save the form with only the signature
      const readable_bool = decisionStrToBool(readable_str);

      if (!readable_bool) {
        await signGradeCase({
          readable_bool,
          case_id: caseId,
          user_email: email,
          signatureDataUri_string: signature,
        });
        toast_success(SUCCESS_GRADING_CASE);
        navigate(ROUTES.GRADING);
        return;
      }

      // get the other disease custom string
      let otherDiseaseCustom_string = '';
      if (otherDiseaseNone_bool) {
        otherDiseaseCustom_string = 'None';
      } else if (otherDiseaseCustom_bool) {
        otherDiseaseCustom_string = otherDiseaseCustomInput_string;
      }

      // validate if at least 2 criteria from 47 are selected
      const twoCriteriaFrom47_bool =
        [
          rest_form.moderateRhsPlusMildIrma_bool,
          rest_form.mildIrmaIn4Quadrants_bool,
          rest_form.severeRhIn2To3Quadrants_bool,
          rest_form.venousBeadingIn1Quadrant_bool,
        ].filter(Boolean).length >= 2;

      // get acknowledgement status
      const acknowledgement_inputs = [];

      if (rest_form.referral_string === '0_months') {
        acknowledgement_inputs.push(
          `${t('grade-question-referral')}: ${t('grade-options-referral.now')}`
        );
      }

      if (rest_form.otherDiseaseFollowUp_string) {
        // get the other disease follow up list
        const listDiseaseFollowUp = [
          otherDiseaseCustom_bool,
          rest_form.otherDiseaseConcernForGlaucoma_bool,
          rest_form.otherDiseaseMacularDrusen_bool,
          rest_form.otherDiseaseMacularPigmentaryDisturbance_bool,
          rest_form.otherDiseaseEpiretinalMembrane_bool,
          rest_form.otherDiseaseChoroidalLesion_bool,
        ];
        const isNoneDiseaseSelected = Boolean(otherDiseaseNone_bool);
        const diseaseLabel = getDiseaseLabel(
          listDiseaseFollowUp,
          isNoneDiseaseSelected,
          otherDiseaseCustom_bool ? otherDiseaseCustomInput_string : undefined
        );

        if (rest_form.otherDiseaseFollowUp_string === '0_months') {
          acknowledgement_inputs.push(
            `${t('grade-acknowledgement-label-follow-up-other-disease')}: ${t('grade-options-referral.now')} (${diseaseLabel})`
          );
        }

        if (rest_form.otherDiseaseFollowUp_string === '1_month') {
          acknowledgement_inputs.push(
            `${t('grade-acknowledgement-label-follow-up-other-disease')}: ${t('grade-options-referral.return-1-mo')} (${diseaseLabel})`
          );
        }

        if (rest_form.otherDiseaseFollowUp_string === '1-3_months') {
          acknowledgement_inputs.push(
            `${t('grade-acknowledgement-label-follow-up-other-disease')}: ${t('grade-options-referral.return-1-to-3-mo')} (${diseaseLabel})`
          );
        }
      }

      if (decisionStrToBool(urgent_str ?? '')) {
        acknowledgement_inputs.push(t('grade-acknowledgement-label-urgent-follow-up'));
      }

      const isTestGraderRole = role === 'testgrader';
      if (isTestGraderRole) {
        toast_success('Success grading case in test grader mode');
        navigate(ROUTES.GRADING);
        setSaving(false);
        return;
      }

      // send the form case to the server
      await signGradeCase({
        ...rest_form,
        readable_bool,
        case_id: caseId,
        user_email: email,
        imagesReadable_string,
        needs_acknowledgement:
          acknowledgement_inputs.length > 0 ? JSON.stringify(acknowledgement_inputs) : undefined,
        signatureDataUri_string: signature,
        evidenceOfDme_bool: decisionStrToBool(evidenceOfDme_str),
        twoCriteriaFrom47_bool: twoCriteriaFrom47_bool,
        otherDiseaseCustom_string,
        urgent_bool: decisionStrToBool(urgent_str ?? ''),
      });

      toast_success(SUCCESS_GRADING_CASE);
      navigate(ROUTES.GRADING);
    } catch (error) {
      errorlog(error as Error, 'src/components/grading/grade-case', 'Error signing case');
    }
    setSaving(false);
  };

  return (
    <FetchStates loading={navigation.state === 'loading' || loadingImages}>
      <section className="page-content fade">
        {!currentGradeCase ? (
          <SetCaseClaimed
            case={currentCase}
            claimed={claimedByUser}
            onSetClaimed={handleSetClaimed}
            onSetSaving={setSaving}
            disabled={Boolean(saving || isGradeBlocked || currentGradeCase)}
          />
        ) : null}
        <GradeCaseImages
          images={images}
          setFormValue={setValue}
          watch={watch}
          enableError={enableImageReadableError}
        />
        <form onSubmit={handleSubmit(onSubmitGradeCase)}>
          {/* Main questions of the form */}
          <GradeFormMainQuestions
            register={register}
            watch={watch}
            setValue={setValue}
            disabled={disabled}
            reset={reset}
          />
          {/* urgent follow up */}
          <GradeFormUrgentConfirm
            register={register}
            watch={watch}
            setValue={setValue}
            disabled={disabled || !isCaseReadable}
          />
          <Hr className="col-span-2" />
          <Signature
            onChange={onSignatureChange}
            initialSignature={currentGradeCase?.signatureDataUri_string}
            disabled={disabled || !readableStr}
          />

          <div className="col-start-1 col-span-2 mt-4 flex justify-between">
            <button
              type="button"
              disabled={disabled}
              className={disabled ? 'cursor-not-allowed' : ''}
              onClick={handleResetForm}
            >
              <span className="mr-2">
                <FontAwesomeIcon icon={faTrashCan} />
              </span>
              {t('clear-form')}
            </button>
            <button
              type={currentGradeCase ? 'button' : 'submit'}
              className="button"
              disabled={disabled || !readableStr}
            >
              {saving ? `... ${t('Processing')}` : t('submit')}
            </button>
          </div>
        </form>
      </section>
    </FetchStates>
  );
}
