import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Case } from '@/api/cases';
import { getRelativeRoute } from '@/router/routes';

type NavigateTo =
  | 'GRADING_CASE_STEP_1'
  | 'GRADING_CASE_STEP_2'
  | 'GRADING_CASE_STEP_3'
  | 'GRADING_CASE_STEP_4';

export function useNavigateFlow() {
  const location: { state?: { case?: Case } } = useLocation();
  const navigate = useNavigate();

  const currentCase = useMemo(() => location.state?.case, [location.state?.case]);

  const navigateFlow = useCallback(
    (to: NavigateTo) => {
      navigate(`../${getRelativeRoute(to)}`, { state: { case: currentCase } });
    },
    [currentCase, navigate]
  );

  return {
    navigateFlow,
  };
}
