import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useOtherDiseaseLabels } from '@/hooks/grade-flow/use-other-diseases-labels';
import { useGradeOtherDiseaseLabel } from '@/hooks/use-grade-other-disease-label';
import { useGradeFlow } from '@/states/grade-flow';
import { OtherDiseaseKey } from '@/states/grade-flow/types';

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/form/dropdown';
import { OptionButton } from '@/components/form/option-button';
import { InputField } from '@/components/form/input-field';
import { MultiDecisionButtons } from '@/components/form/multi-decision-buttons';

export function OtherDiseaseQuestion() {
  const { t } = useTranslation();
  const { getDiseaseLabel } = useGradeOtherDiseaseLabel();

  const { DISEASE_FOLLOW_UP_OPTIONS, DISEASE_OPTIONS_DROPDOWN } = useOtherDiseaseLabels();

  const {
    form,
    isDisabled,
    isOtherCustomDiseaseSelected,
    isOtherDiseaseNoneSelected,
    setIsOtherDiseaseNoneSelected,
    setIsOtherCustomDiseaseSelected,
    setOtherDiseaseValue,
    setCustomDiseaseValue,
    setOtherDiseaseFollowUp,
  } = useGradeFlow(
    useShallow((s) => ({
      isDisabled: s.form.is_readable === undefined || s.isSavingGrade,
      isOtherCustomDiseaseSelected: s.isOtherCustomDiseaseSelected,
      isOtherDiseaseNoneSelected: s.isOtherDiseaseNoneSelected,
      setIsOtherDiseaseNoneSelected: s.setIsOtherDiseaseNoneSelected,
      setIsOtherCustomDiseaseSelected: s.setIsOtherCustomDiseaseSelected,
      setOtherDiseaseValue: s.setOtherDiseaseValue,
      setCustomDiseaseValue: s.setCustomDiseaseValue,
      setOtherDiseaseFollowUp: s.setOtherDiseaseFollowUp,
      form: s.form,
    }))
  );

  const otherSelectLabel = useMemo(() => {
    return getDiseaseLabel(
      [
        isOtherCustomDiseaseSelected,
        Boolean(form.other_disease?.concern_for_glaucoma),
        Boolean(form.other_disease?.macular_drusen),
        Boolean(form.other_disease?.macular_pigmentary_disturbance),
        Boolean(form.other_disease?.epiretinal_membrane),
        Boolean(form.other_disease?.choroidal_lesion),
      ],
      isOtherDiseaseNoneSelected
    );
  }, [
    getDiseaseLabel,
    form.other_disease?.choroidal_lesion,
    form.other_disease?.concern_for_glaucoma,
    form.other_disease?.epiretinal_membrane,
    form.other_disease?.macular_drusen,
    form.other_disease?.macular_pigmentary_disturbance,
    isOtherCustomDiseaseSelected,
    isOtherDiseaseNoneSelected,
  ]);

  const enableFollowUpQuestion = useMemo(() => {
    return (
      isOtherCustomDiseaseSelected ||
      Boolean(form.other_disease?.concern_for_glaucoma) ||
      Boolean(form.other_disease?.macular_drusen) ||
      Boolean(form.other_disease?.macular_pigmentary_disturbance) ||
      Boolean(form.other_disease?.epiretinal_membrane) ||
      Boolean(form.other_disease?.choroidal_lesion)
    );
  }, [
    isOtherCustomDiseaseSelected,
    form.other_disease?.choroidal_lesion,
    form.other_disease?.concern_for_glaucoma,
    form.other_disease?.epiretinal_membrane,
    form.other_disease?.macular_drusen,
    form.other_disease?.macular_pigmentary_disturbance,
  ]);

  const handleSelectOtherDisease = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- at this time we dont need the current value
    (_: string) => setIsOtherDiseaseNoneSelected(!isOtherDiseaseNoneSelected),
    [setIsOtherDiseaseNoneSelected, isOtherDiseaseNoneSelected]
  );

  const handleSelectCustomDisease = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- at this time we dont need the current value
    (_: string) => setIsOtherCustomDiseaseSelected(!isOtherCustomDiseaseSelected),
    [setIsOtherCustomDiseaseSelected, isOtherCustomDiseaseSelected]
  );

  const handleSelectOption = useCallback(
    (value: string) => {
      const key = value as OtherDiseaseKey;
      setOtherDiseaseValue(key, !form.other_disease![key]);
    },
    [setOtherDiseaseValue, form]
  );

  const handleOtherDiseaseInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setCustomDiseaseValue(e.target.value);
    },
    [setCustomDiseaseValue]
  );

  const handleSelectFollowUp = useCallback(
    (selected: string) => {
      setOtherDiseaseFollowUp(selected);
    },
    [setOtherDiseaseFollowUp]
  );

  return (
    <>
      <fieldset>
        <label
          htmlFor="other-disease"
          className="text-base/5 font-medium text-gray-800 mt-8 mb-4 text-center"
        >
          {t('grade-question-other-disease')}
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger disabled={isDisabled} asChild>
            <button
              id="other-disease"
              type="button"
              className="Select input-field text-start max-w-[640px]"
              disabled={isDisabled}
            >
              {otherSelectLabel}
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="start"
            className="max-w-60 md:max-w-96 md:min-w-60 flex flex-col"
          >
            <OptionButton
              disabled={isDisabled}
              value="_none"
              variant="clean"
              active={isOtherDiseaseNoneSelected}
              onClick={handleSelectOtherDisease}
              className="border-b-[1px] border-gray-200"
            >
              {t('grade-question-other-diseases-options.none')}
            </OptionButton>
            <OptionButton
              disabled={isDisabled}
              value="_other"
              variant="clean"
              active={isOtherCustomDiseaseSelected}
              onClick={handleSelectCustomDisease}
              className="border-b-[1px] border-gray-200"
            >
              {t('grade-question-other-diseases-options.other')}
            </OptionButton>
            {DISEASE_OPTIONS_DROPDOWN.map((option) => (
              <OptionButton
                key={option.value}
                disabled={isDisabled}
                value={option.value}
                variant="clean"
                active={form.other_disease![option.value]}
                onClick={handleSelectOption}
                className="border-b-[1px] border-gray-200"
              >
                {option.label}
              </OptionButton>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </fieldset>

      {/* OTHER OPTION STRING */}
      {isOtherCustomDiseaseSelected && (
        <InputField
          type="text"
          label={t('grade-question-other-disease-specify')}
          classNameFieldSet="mt-4 md:min-w-[640px]"
          required
          value={form.other_disease?.custom}
          onChange={handleOtherDiseaseInputChange}
          name="other-disease"
          disabled={isDisabled}
        />
      )}

      {enableFollowUpQuestion && (
        <MultiDecisionButtons
          label={t('grade-question-needs-follow-up')}
          disabled={isDisabled}
          options={DISEASE_FOLLOW_UP_OPTIONS}
          selected={form?.other_disease?.follow_up ? [form?.other_disease?.follow_up] : []}
          onSelection={handleSelectFollowUp}
        />
      )}
    </>
  );
}
