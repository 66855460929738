import { LoadingDots } from './loading-dots';
import logo from '../assets/images/vitazi-logo-transparent.png';

export function LoadingInitial() {
  return (
    <div className="h-screen w-full flex-full-center flex-col">
      <div className="flex-1 flex-full-center flex-col mb-16">
        <img className="w-48 object-scale-down" src={logo} alt="Vitazi.ai Logo" />
      </div>
      <div className="mb-8">
        <LoadingDots />
      </div>
    </div>
  );
}
