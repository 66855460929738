import { useMemo, useCallback, Suspense } from 'react';
import { Outlet, useNavigation, useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLongToLine } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { useGetGradingCases } from '@/hooks/api/use-get-grading-cases';
import { useUserProfile } from '@/states/user-profile';
import { parseDateString } from '@/utils/dates';
import { ROUTES } from '@/router/routes';

import { FetchStates } from './fetch-states';
import { Hr } from './divider';
import { LabelValue } from './label-value';
import { GradingTable } from './grading/grading-table';
import { Alert } from './alert';

export function GradingPage() {
  const navigate = useNavigate();
  const navigation = useNavigation();

  const { t } = useTranslation();
  const { caseId: caseSelectedId } = useParams();
  const { cases, loading, filter, filterByGradedCases } = useGetGradingCases();

  const { email } = useUserProfile((state) => ({ email: state.profile.email ?? '' }));

  const caseSelected = useMemo(
    () => cases.find((c) => c.case_id === caseSelectedId),
    [cases, caseSelectedId]
  );

  // We will show a banner if the case is already claimed
  // only if the claimed user is not the current user and the case is not older than 30 minutes
  const caseClaimedLabel = useMemo(() => {
    if (!caseSelected?.claimed_by) return false;

    const claimed_by = caseSelected.claimed_by;
    const claimed_time = caseSelected.claimed_time;

    if (claimed_by === email) {
      return false;
    }

    if (claimed_time) {
      const claimedDate = new Date(claimed_time);
      const futureDate = new Date(claimedDate.getTime() + 30 * 60 * 1000); // 30 minutes in the future
      if (futureDate > new Date()) {
        return `This case is already claimed, will be available at: ${futureDate.toLocaleTimeString()}`;
      }
    }

    return false;
  }, [caseSelected?.claimed_by, caseSelected?.claimed_time, email]);

  const formatDate = useCallback(
    // format date from '2024-03-14T14:57:21:139969'
    (date: string) => parseDateString(date)?.toLocaleDateString() ?? 'Invalid Date',
    []
  );

  return (
    <div className="page-content-wrapper paper">
      <header className="page-header">
        <h1 className="page-title">{t('grading-title')}</h1>
        {caseSelected && (
          <div className="inline-flex-full-center gap-12">
            <div className="inline-flex gap-8">
              <LabelValue label="User Email" value={caseSelected.user_email} />
              <LabelValue label="Created At" value={formatDate(caseSelected.date_created)} />
            </div>
            <button type="button" onClick={() => navigate(ROUTES.GRADING)}>
              <FontAwesomeIcon icon={faArrowLeftLongToLine} />
            </button>
          </div>
        )}
      </header>
      {caseClaimedLabel ? (
        <Alert type="warning" title={t('grade-claimed-case-title')}>
          {caseClaimedLabel}
        </Alert>
      ) : null}
      <FetchStates loading={(loading && !caseSelected) || navigation.state === 'loading'}>
        <div className="page-content">
          {!caseSelected && (
            <GradingTable cases={cases} filter={filter} filterByGradedCases={filterByGradedCases} />
          )}
        </div>
      </FetchStates>
      <Hr />
      <Suspense fallback={<FetchStates loading />}>
        <Outlet />
      </Suspense>
    </div>
  );
}
