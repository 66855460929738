import { useState, useCallback, Fragment } from 'react';
import type { ReactNode, TableHTMLAttributes, ComponentType } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashAlt, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

import { RequestStateValue } from './state-request-value';
import { GradeStateValue } from './state-grade-value';
import { StatusValue } from './status-value';

type TableValue = unknown;
type TableRow = Record<string, TableValue>;
type TableData = TableRow[];

type BaseHeader = {
  key: string;
  label?: string;
  format?: (value: TableValue, row?: TableRow) => ReactNode;
  tooltip?: (value: TableRow) => ReactNode;
  action?: ReactNode;
};

type TableHeaderAction = BaseHeader & {
  type: 'actions';
  edit: (row: TableRow) => void;
  delete: (row: TableRow) => void;
};

type TableHeaderState = BaseHeader & {
  type: 'request-state' | 'grade-state' | 'status';
};

type TableHeaderCollapsible = BaseHeader & {
  type: 'collapsible';
  value?: (row?: TableRow) => ReactNode;
  hide?: (row?: TableRow) => boolean;
};

type TableHeaderSummaryRow = BaseHeader & {
  type: 'summary';
  value: (row?: TableRow) => ReactNode;
};

type TableHeaderValue = BaseHeader & {
  type?: 'value';
};

type TableHeader =
  | TableHeaderValue
  | TableHeaderAction
  | TableHeaderState
  | TableHeaderCollapsible
  | TableHeaderSummaryRow;

type TableProps<T extends TableData> = TableHTMLAttributes<HTMLTableElement> & {
  headers: TableHeader[];
  data: T;
  bodyClassName?: string;
  onRowClick?: (item: T[number]) => void;
  CollapsibleComponent?: ComponentType<{ item: T[number] }>;
};

export const Table = function Table<T extends TableData>(props: TableProps<T>) {
  const {
    headers = [],
    data = [],
    onRowClick,
    bodyClassName = '',
    className = '',
    CollapsibleComponent,
    ...rest
  } = props;
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const toggleRow = useCallback(
    (index: number) => {
      setExpandedRows((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
      );
    },
    [setExpandedRows]
  );

  return (
    <table
      className={`CustomTable table-auto border-collapse min-w-full divide-y divide-gray-300 ${className}`}
      {...rest}
    >
      <thead>
        <tr>
          {headers.map((header) => {
            const key = `table-header-${header.key}`;
            const isActionsColumn = header.type === 'actions';
            const isCollapsibleColumn = header.type === 'collapsible';

            return (
              <th
                key={key}
                className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {!isActionsColumn && !isCollapsibleColumn ? (
                  <>
                    {header.label ?? header.key}
                    {header.action ? <span className="ml-1">{header.action}</span> : null}
                  </>
                ) : isCollapsibleColumn ? (
                  header.label
                ) : null}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={`${bodyClassName} divide-y divide-gray-200`}>
        {data.map((row, index) => (
          <Fragment key={`table-row-group-${index}`}>
            <tr
              key={`table-row-${index}`}
              className="bg-white"
              onClick={onRowClick ? () => onRowClick(row) : undefined}
              role={onRowClick ? 'button' : undefined}
              tabIndex={onRowClick ? 0 : undefined}
              style={onRowClick ? { cursor: 'pointer' } : undefined}
              aria-expanded={expandedRows.includes(index)}
            >
              {headers.map((header) => {
                const key = `table-row-${index}-${header.key}`;
                const tooltipId = `${key}-tooltip`;

                if (header.type === 'actions') {
                  return (
                    <td key={key} className="text-gray-600 hover:text-gray-900">
                      <button
                        className="text-gray-600 hover:text-gray-900"
                        onClick={(e) => {
                          e.stopPropagation();
                          header.edit(row);
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        className="text-gray-600 hover:text-gray-900 ml-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          header.delete(row);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                  );
                }

                if (header.type === 'collapsible') {
                  // Sometimes we want to hide the collapsible row, for example when there are no data to show
                  const shouldHideCollapsible = header.hide ? header.hide(row) : false;
                  if (shouldHideCollapsible) return null;

                  const collapsibleKey = `table-row-${index}-collapsible-${header.key}`;
                  const collapsibleLabel = expandedRows.includes(index)
                    ? 'Collapse ' + header.label
                    : 'Expand ' + header.label;

                  const handleCollapseClick = (e: React.MouseEvent) => {
                    e.stopPropagation();
                    toggleRow(index);
                  };

                  return (
                    <td key={key} className="whitespace-nowrap w-[124px]">
                      <button
                        onClick={handleCollapseClick}
                        aria-label={collapsibleLabel}
                        data-tooltip-id={collapsibleKey}
                      >
                        <FontAwesomeIcon
                          className={`inline mx-2 transform transition-transform duration-300 ${
                            expandedRows.includes(index) ? 'rotate-180' : 'rotate-0'
                          }`}
                          icon={faChevronDown}
                        />
                      </button>
                      {header.value ? header.value(row) : null}

                      <Tooltip id={collapsibleKey} place="top">
                        {collapsibleLabel}
                      </Tooltip>
                    </td>
                  );
                }

                const value = header.format ? header.format(row[header.key], row) : row[header.key];

                const tooltip = header.tooltip ? (
                  <Tooltip id={key} place="top" clickable>
                    {header.tooltip(row)}
                  </Tooltip>
                ) : null;

                if (header.type === 'status') {
                  return (
                    <td key={key} data-tooltip-id={key}>
                      <span aria-describedby={tooltipId}>
                        <StatusValue value={value as boolean} />
                      </span>
                      {tooltip}
                    </td>
                  );
                }

                if (header.type === 'request-state') {
                  return (
                    <td key={key} data-tooltip-id={key}>
                      <span aria-describedby={tooltipId}>
                        <RequestStateValue value={value as string} />
                      </span>
                      {tooltip}
                    </td>
                  );
                }

                if (header.type === 'grade-state') {
                  return (
                    <td key={key} data-tooltip-id={key}>
                      <span aria-describedby={tooltipId}>
                        <GradeStateValue value={value as number} />
                      </span>
                      {tooltip}
                    </td>
                  );
                }

                if (header.type === 'summary') {
                  return (
                    <td key={key} data-tooltip-id={key}>
                      <span aria-describedby={tooltipId}>{header.value(row)}</span>
                      {tooltip}
                    </td>
                  );
                }

                const plainValue = (value as ReactNode) ?? '-';

                return (
                  <td key={key} className="whitespace-nowrap">
                    <span aria-describedby={tooltipId} data-tooltip-id={key}>
                      {typeof plainValue === 'string' ? String(plainValue) : plainValue}
                    </span>
                    {tooltip}
                  </td>
                );
              })}
            </tr>

            {expandedRows.includes(index) && CollapsibleComponent && (
              <tr className="nested shadow-inner">
                <td colSpan={headers.length} className="pr-6">
                  <div className="drop-shadow-sm">
                    <CollapsibleComponent item={row} />
                  </div>
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};
