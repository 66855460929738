import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '@/router/routes';
import { Case } from '@/api/cases';

import { ProgressBullets } from '@/components/progress-bullets';

const FLOW_ROUTES = [
  { name: 'Get Started', href: ROUTES.GRADING_CASE_STEP_1 },
  { name: 'Readable Images', href: ROUTES.GRADING_CASE_STEP_2 },
  { name: 'Questions', href: ROUTES.GRADING_CASE_STEP_3 },
  { name: 'Signature', href: ROUTES.GRADING_CASE_STEP_4 },
];

export function HeadingGradeFlow() {
  const location: { state?: { case?: Case }; pathname: string } = useLocation();

  const steps = useMemo(() => {
    const currentPath = location.pathname.split('/').pop();
    const currentCase = location.state?.case;
    if (!currentPath || !currentCase) return [];

    return FLOW_ROUTES.map((route, routeIndex, arr) => {
      const routePath = route.href.split('/').pop();

      if (currentPath === routePath) {
        return {
          ...route,
          status: 'current' as const,
          href: route.href.replace(':caseId', currentCase.case_id),
        };
      }

      const currentPathIndex = arr.findIndex((r) => r.href.split('/').pop() === currentPath);
      const status = currentPathIndex > routeIndex ? ('complete' as const) : ('upcoming' as const);

      return { ...route, status, href: route.href.replace(':caseId', currentCase.case_id) };
    });
  }, [location.pathname, location.state?.case]);

  return <ProgressBullets steps={steps} state={{ case: location.state?.case }} />;
}
