import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faLock } from '@fortawesome/pro-solid-svg-icons';
import { faFilter as faFilterOutlined } from '@fortawesome/pro-light-svg-icons';

import type { Case } from '@/api/cases';
import type { CaseFilters } from '@/hooks/api/use-get-grading-cases';
import { parseDateString } from '@/utils/dates';

import { Table } from '@/components/table';

type GradingTableProps = {
  cases: Case[];
  filter: CaseFilters;
  filterByGradedCases: () => void;
};

export function GradingTable(props: GradingTableProps) {
  const { cases, filter, filterByGradedCases } = props;
  const navigate = useNavigate();

  const formatDate = useCallback(
    (
      date: unknown // format date from '2024-03-14T14:57:21:139969'
    ) => parseDateString(date as string)?.toLocaleDateString() ?? 'Invalid Date',
    []
  );

  const formatGradeState = useCallback(
    (grades: unknown) => ((grades as string).length > 0 ? '✅' : '➖') as string,
    []
  );

  const formatId = useCallback((id: unknown) => {
    if (typeof id === 'string') {
      return id ? id.slice(0, 3) + '..' + id.slice(-5) : '';
    }
    return '';
  }, []);

  const tooltipCaseId = useCallback(
    (case_row: unknown) => (case_row as (typeof cases)[number]).case_id,
    []
  );

  const formatLocation = useCallback((location: unknown) => {
    if (!location) return '-';

    const loc = location as { city: string; country: string; province_or_state: string };
    return `${loc.city}, ${loc.province_or_state}`;
  }, []);

  const tooltipClaimedId = useCallback((case_row: unknown) => {
    // we have the claimed_by and claimed_time, here we want to display a tooltip with future time when it will be available based on claimed_time
    // claimed_time is a string in the ISO format "2024-10-11T09:15:05.498+00:00"
    const claimed_time = (case_row as (typeof cases)[number]).claimed_time;
    if (claimed_time) {
      const claimedDate = new Date(claimed_time);
      const futureDate = new Date(claimedDate.getTime() + 30 * 60 * 1000); // 30 minutes in the future
      return `This case was claimed, will be available at: ${futureDate.toLocaleTimeString()}`;
    }
    return '';
  }, []);

  const formatClaimed = useCallback((claimed: unknown) => {
    if (!claimed) return '➖';

    const claimed_time = new Date(claimed as string);
    const futureDate = new Date(claimed_time.getTime() + 30 * 60 * 1000); // 30 minutes in the future
    return futureDate > new Date() ? <FontAwesomeIcon icon={faLock} /> : '➖';
  }, []);

  const gradesTableHeaders = useMemo(
    () => [
      {
        key: 'case_id',
        label: 'Case #',
        type: 'value' as const,
        format: formatId,
        tooltip: tooltipCaseId,
      },
      { key: 'date_created', label: 'Created at', format: formatDate },
      { key: 'location', label: 'Location', format: formatLocation },
      {
        key: 'grades',
        label: 'Graded',
        format: formatGradeState,
        action: (
          <button onClick={filterByGradedCases}>
            <FontAwesomeIcon icon={filter.graded ? faFilter : faFilterOutlined} />
          </button>
        ),
      },
      {
        key: 'claimed_time',
        label: 'Claimed',
        format: formatClaimed,
        tooltip: tooltipClaimedId,
      },
    ],
    [
      formatId,
      tooltipCaseId,
      formatDate,
      formatGradeState,
      formatLocation,
      tooltipClaimedId,
      formatClaimed,
      filter,
      filterByGradedCases,
    ]
  );

  const handleClickCase = useCallback(
    (row: (typeof cases)[number]) => {
      navigate(row.case_id, { state: { case: row } });
    },
    [navigate]
  );

  return <Table data={cases} headers={gradesTableHeaders} onRowClick={handleClickCase} />;
}
