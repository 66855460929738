import { Route } from 'react-router-dom';

import { GradingPage } from '@/components/grading-page';
import { GradeCase } from '@/components/grading/grade-case';
import { GetStartedStep } from '@/components/grading/grade-flow/step-1';
import { ReadableImagesStep } from '@/components/grading/grade-flow/step-2';
import { QuestionsStep } from '@/components/grading/grade-flow/step-3';
import { SignatureStep } from '@/components/grading/grade-flow/step-4';

import { gradeLoader, protectedLoader } from './loaders';
import { ROUTES } from './routes';

export const GradingRoutes = (
  <Route path={ROUTES.GRADING} element={<GradingPage />} loader={protectedLoader}>
    <Route
      path={ROUTES.GRADING_CASE}
      element={<GradeCase />}
      loader={gradeLoader}
      id="grade-case"
      shouldRevalidate={() => false} // for some reason when redirecting to the some child route, the parent route is re-requesting the data
    >
      <Route path={ROUTES.GRADING_CASE_STEP_1} element={<GetStartedStep />} />
      <Route path={ROUTES.GRADING_CASE_STEP_2} element={<ReadableImagesStep />} />
      <Route path={ROUTES.GRADING_CASE_STEP_3} element={<QuestionsStep />} />
      <Route path={ROUTES.GRADING_CASE_STEP_4} element={<SignatureStep />} />
    </Route>
  </Route>
);
