import { useEffect } from 'react';
import { useRouteLoaderData } from 'react-router-dom';

import { useShallow } from 'zustand/react/shallow';

import { useGradeFlow } from '@/states/grade-flow';
import { getCaseImage } from '@/api/cases';

type LoaderData = { caseImgs: string[] };

export const useInitLoadImages = () => {
  const loader = useRouteLoaderData('grade-case') as LoaderData;

  const { setInitialReadableImages, images, setImages, isLoadingImages, setIsLoadingImages } =
    useGradeFlow(
      useShallow((s) => ({
        setInitialReadableImages: s.setInitialReadableImages,
        images: s.images,
        setImages: s.setImages,
        isLoadingImages: s.isLoadingImages,
        setIsLoadingImages: s.setIsLoadingImages,
      }))
    );

  // this effect will load the case images
  useEffect(() => {
    if (Boolean(images?.length) || isLoadingImages || !loader?.caseImgs.length) return;

    setIsLoadingImages(true);
    void (async () => {
      const urls = loader?.caseImgs.filter((url) => !url.includes('.zip'));
      const response = await Promise.all(urls.map(getCaseImage));
      if (response) {
        const images = response.filter((response) => response.file instanceof Blob);
        if (images.length) {
          setImages(
            images.map((img) => ({ path: img.path, file_uri: URL.createObjectURL(img.file) }))
          );
          setInitialReadableImages(images.map((img) => img.path));
        }
      }
      setIsLoadingImages(false);
    })();
  }, [
    images,
    isLoadingImages,
    loader?.caseImgs,
    setImages,
    setInitialReadableImages,
    setIsLoadingImages,
  ]);
};
