import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useShallow } from 'zustand/react/shallow';

import { useGradeFlow } from '@/states/grade-flow';

import { Overlay } from '@/components/overlay';
import { Carousel } from '@/components/carousel';
import { RadioGroupOption } from '@/components/form/radio-group-opt';
import { LoadingDots } from '@/components/loading-dots';

type GradeReadableImagesProps = {
  enableError?: boolean;
  size?: 'sm' | 'md';
  filter?: 'all' | 'left' | 'right';
  displayTitle?: boolean;
};

const SIZE_MAP_STYLE = {
  sm: 'h-24',
  md: 'h-40',
};

export function GradeReadableImages(props: GradeReadableImagesProps) {
  const { enableError, displayTitle, filter = 'all', size = 'md' } = props;
  const [expanded, setExpanded] = useState<number | undefined>();
  const { t } = useTranslation();

  const { isLoadingImages, images, readableImages, setImageReadable, isSaving } = useGradeFlow(
    useShallow((s) => ({
      isLoadingImages: s.isLoadingImages,
      isSaving: s.isSavingGrade,
      images: s.images,
      readableImages: s.form.images_readable,
      setImageReadable: s.setImageReadable,
    }))
  );

  const imagesFiltered = useMemo(() => {
    if (filter === 'all') {
      return images ?? [];
    }

    return images?.filter((i) => i.lat === filter) ?? [];
  }, [images, filter]);

  const handleClose = useCallback(() => {
    setExpanded(undefined);
  }, []);

  const handleExpand = useCallback(
    (index: number) => {
      if (isSaving) return;

      setExpanded(index);
    },
    [isSaving]
  );

  const imageChildren = (img: string) => {
    const currentState = readableImages?.find((state) => state.img === img) ?? {
      is_readable: '',
    };

    return (
      <div className="absolute top-12 right-0 rounded-lg bg-white bg-opacity-90 p-4 inline-flex flex-col">
        <p>{t('grade-image-readable-question')}</p>
        <RadioGroupOption
          label={t('yes')}
          id={`${img}-readable-yes`}
          value="yes"
          required
          disabled={isSaving}
          checked={currentState.is_readable === 'yes'}
          onChange={() => setImageReadable(img, true)}
        />
        <RadioGroupOption
          label={t('no')}
          id={`${img}-readable-no`}
          value="no"
          required
          disabled={isSaving}
          checked={currentState.is_readable === 'no'}
          onChange={() => setImageReadable(img, false)}
        />
      </div>
    );
  };

  if (isLoadingImages) {
    return (
      <div className="flex-full-center my-12">
        <LoadingDots />
      </div>
    );
  }

  if (!imagesFiltered?.length) {
    return <p className="pb-4 text-gray-500 text-sm">{t('grade-no-image-available')}</p>;
  }

  return (
    <>
      {displayTitle ? (
        <h2 className="text-base/7 font-semibold text-gray-900 text-center mb-4">
          {t('grade-image-readable-title')}
        </h2>
      ) : null}
      <div className="flex-full-center gap-4 overflow-x-auto">
        {imagesFiltered.map((i, index) => {
          const image = readableImages?.find((state) => state.img === i.path);
          const isImageUndefined = image?.is_readable === '';
          const isImageReadable = image?.is_readable === 'yes';

          let color = 'text-gray-500';

          if (isImageReadable) {
            color = 'text-green-500';
          } else if (isImageUndefined) {
            color = 'text-gray-500';
          } else {
            color = 'text-yellow-500';
          }

          if (isImageUndefined && enableError) {
            color = 'text-red-500';
          }

          return (
            <div key={`grade-image-${index}`} className="cursor-pointer">
              <img
                className={`${SIZE_MAP_STYLE[size]} object-scale-down pointer`}
                src={i.file_uri}
                alt={`Case img ${index + 1}`}
                onClick={() => handleExpand(index)}
              />
              <div className="h-4">
                {image ? (
                  <p className={`text-xs text-center font-medium ${color}`}>
                    {isImageUndefined ? (
                      t('unanswered')
                    ) : isImageReadable ? (
                      <>
                        <span className="mr-1">{t('readable')}</span>
                        <FontAwesomeIcon icon={faBadgeCheck} />
                      </>
                    ) : (
                      <>
                        <span className="mr-1">{t('unreadable')}</span>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                      </>
                    )}
                  </p>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      <Overlay open={expanded !== undefined} onClose={handleClose}>
        <div className="h-[80vh] w-full m-auto mt-[5%]">
          <Carousel
            images={imagesFiltered.map((i) => ({ path: i.path, url: i.file_uri }))}
            initialSlide={expanded}
            imagesChildren={imageChildren}
          />
        </div>
      </Overlay>
    </>
  );
}
