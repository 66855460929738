import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type BadgeVariant = 'flat' | 'outline';
type BadgeColor = 'gray' | 'red' | 'yellow' | 'green' | 'blue';
type BadgeSize = 'xs' | 'sm';

type BadgeProps = React.HTMLAttributes<HTMLSpanElement> & {
  variant?: BadgeVariant;
  color?: BadgeColor;
  size?: BadgeSize;
  children: React.ReactNode;
  isLoading?: boolean;
};

const VARIANT_MAP = {
  gray: {
    flat: 'bg-gray-100 text-gray-600',
    outline:
      'dark:bg-gray-400/10 bg-gray-50 ring-1 ring-inset dark:ring-gray-400/20 ring-gray-500/10 dark:text-gray-700 text-gray-600 ',
  },
  red: {
    flat: 'bg-red-100 text-red-700',
    outline:
      'dark:bg-red-400/10 bg-red-50 ring-1 ring-inset dark:ring-red-400/20 ring-red-500/10 dark:text-red-700 text-red-700 ',
  },
  yellow: {
    flat: 'bg-yellow-100 text-yellow-800',
    outline:
      'dark:bg-yellow-400/10 bg-yellow-50 ring-1 ring-inset dark:ring-yellow-400/20 ring-yellow-500/10 dark:text-yellow-700 text-yellow-700 ',
  },
  green: {
    flat: 'bg-green-100 text-green-700',
    outline:
      'dark:bg-green-400/10 bg-green-50 ring-1 ring-inset dark:ring-green-400/20 ring-green-500/10 dark:text-green-700 text-green-700 ',
  },
  blue: {
    flat: 'bg-blue-100 text-blue-700',
    outline:
      'dark:bg-blue-400/10 bg-blue-50 ring-1 ring-inset dark:ring-blue-400/20 ring-blue-500/10 dark:text-blue-700 text-blue-700 ',
  },
};

const SIZE_MAP = {
  xs: 'px-1.5 py-0.5',
  sm: 'px-2 py-1',
};

export function Badge(props: BadgeProps) {
  const {
    variant = 'flat',
    color = 'gray',
    size = 'sm',
    children,
    className = '',
    isLoading = false,
    ...rest
  } = props;

  return (
    <span
      {...rest}
      className={clsx(
        'inline-flex items-center rounded-full text-xs font-medium',
        className,
        VARIANT_MAP[color][variant],
        SIZE_MAP[size]
      )}
    >
      {isLoading ? (
        <>
          <span className="mr-2 animate-spin">
            <FontAwesomeIcon icon={faSpinner} />
          </span>
          <span>Loading...</span>
        </>
      ) : (
        children
      )}
    </span>
  );
}
