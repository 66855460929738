import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { DEFAULT_PEAR_EYE_GRADE_FORM, EYE_MAP, DEFAULT_STATE } from './default-data';
import { GradeFlowState } from './types';

export const useGradeFlow = create<GradeFlowState>()(
  immer((set) => ({
    ...DEFAULT_STATE,
    setFlowBlocked: (blocked) => {
      set((state) => {
        state.isFlowBlocked = blocked;
      });
    },
    setCaseValidClaimed: (claimed) => {
      set((state) => {
        state.isCaseValidClaimed = claimed;
        state.claimedAt = new Date();
      });
    },
    initializeFlow: (_case) => {
      set((state) => {
        state.case = _case;
        state.form.case_id = _case.case_id;
      });
    },
    setImages: (imgs) => {
      set((state) => {
        // OD for right eye, OS for left eye
        const imageHasLaterality = imgs.some(
          (img) => img.path.includes('OD') || img.path.includes('OS')
        );

        state.isLateralityPresentOnImgs = imageHasLaterality;

        if (!imageHasLaterality) {
          state.images = imgs;
          return;
        }

        state.images = imgs.map((img) => assignLateralityToImages(img));
      });
    },
    setIsLoadingImages: (loading) => {
      set((state) => {
        state.isLoadingImages = loading;
      });
    },
    setInitialReadableImages: (imgs) => {
      set((state) => {
        state.form.images_readable = imgs.map((img) => ({ img, is_readable: '' }));
      });
    },
    setImageReadable: (img, isReadable) => {
      set((state) => {
        if (!state.form.images_readable) return;
        state.form.images_readable = state.form.images_readable.map((i) =>
          i.img === img ? { img, is_readable: isReadable ? 'yes' : 'no' } : i
        );
      });
    },
    setIsReadable: (isReadable) => {
      set((state) => {
        state.form.is_readable = isReadable;
      });
    },
    // questions pear eye
    setQuestionEvidenceDme: (evidenciaDme, eye) => {
      set((state) => {
        const eyeToGrade = EYE_MAP[eye];
        if (!state.form[eyeToGrade]) {
          state.form[eyeToGrade] = {
            ...DEFAULT_PEAR_EYE_GRADE_FORM,
            evidence_of_dme: evidenciaDme,
          };
          return;
        }
        state.form[eyeToGrade]!.evidence_of_dme = evidenciaDme;
      });
    },
    setQuestionDrGrade: (grade_scale, eye) => {
      set((state) => {
        const eyeToGrade = EYE_MAP[eye];
        if (!state.form[eyeToGrade]) {
          state.form[eyeToGrade] = { ...DEFAULT_PEAR_EYE_GRADE_FORM, dr_grade_scale: grade_scale };
        }
        state.form[eyeToGrade]!.dr_grade_scale = grade_scale;
      });
    },
    setQuestionScaleValue: (selected, scale, eye) => {
      set((state) => {
        // We were getting a typescript issue so we had to add this switch statement
        switch (scale.segment) {
          case 'scale_10':
            state.form[EYE_MAP[eye]]!.scale_10[scale.key] = selected;
            break;
          case 'scale_20_35':
            state.form[EYE_MAP[eye]]!.scale_20_35[scale.key] = selected;
            break;
          case 'scale_43':
            state.form[EYE_MAP[eye]]!.scale_43[scale.key] = selected;
            break;
          case 'scale_47':
            state.form[EYE_MAP[eye]]!.scale_47[scale.key] = selected;
            break;
          case 'scale_53':
            state.form[EYE_MAP[eye]]!.scale_53[scale.key] = selected;
            break;
          case 'scale_61':
            state.form[EYE_MAP[eye]]!.scale_61[scale.key] = selected;
            break;
          case 'scale_65':
            state.form[EYE_MAP[eye]]!.scale_65[scale.key] = selected;
            break;
          case 'scale_71_75':
            state.form[EYE_MAP[eye]]!.scale_71_75[scale.key] = selected;
            break;
          case 'scale_81_85':
            state.form[EYE_MAP[eye]]!.scale_81_85[scale.key] = selected;
            break;
          default:
            break;
        }
      });
    },
    clearProliferativeValues: (eye) => {
      set((state) => {
        const eyeToGrade = EYE_MAP[eye];
        state.form[eyeToGrade]!.scale_61.nve_greater_than_half_da_in_1_plus_quadrants_61 = false;
        state.form[eyeToGrade]!.scale_65.nve_greater_than_half_da_in_1_plus_quadrants_65 = false;
        state.form[eyeToGrade]!.scale_65.nvd_less_than_three_quarters = false;
        state.form[eyeToGrade]!.scale_71_75.larger_nvd_or_nve_greater_than_or_equal_to_half_da =
          false;
        state.form[eyeToGrade]!.scale_81_85.view_partially_obscured_by_vh = false;
      });
    },
    clearSevereValues: (eye) => {
      set((state) => {
        const eyeToGrade = EYE_MAP[eye];
        state.form[eyeToGrade]!.scale_53.severe_rhs_in_4_quadrants = false;
        state.form[eyeToGrade]!.scale_53.moderate_to_severe_irma_in_1_plus_quadrants = false;
        state.form[eyeToGrade]!.scale_53.venous_beading_in_2_plus_quadrants = false;

        state.clearProliferativeValues(eye);
      });
    },
    clearModerateValues: (eye) => {
      set((state) => {
        const eyeToGrade = EYE_MAP[eye];
        state.form[eyeToGrade]!.scale_47.moderate_rhs_plus_mild_irma = false;
        state.form[eyeToGrade]!.scale_47.mild_irma_in_4_quadrants = false;
        state.form[eyeToGrade]!.scale_47.severe_rhs_in_2_to_3_quadrants = false;
        state.form[eyeToGrade]!.scale_47.venous_beading_in_1_quadrant = false;

        state.clearSevereValues(eye);
      });
    },
    clearMildValues: (eye) => {
      set((state) => {
        const eyeToGrade = EYE_MAP[eye];
        state.form[eyeToGrade]!.scale_20_35.microaneurysms = false;
        state.form[eyeToGrade]!.scale_20_35.hard_exudates = false;
        state.form[eyeToGrade]!.scale_20_35.cws = false;
        state.form[eyeToGrade]!.scale_20_35.mild_rhs = false;

        state.clearModerateValues(eye);
      });
    },
    setSignature: (signature) => {
      set((state) => {
        state.form.signature_data_uri = signature;
      });
    },
    setReferralDrFollowUp: (selected) => {
      set((state) => {
        state.form.referral_dr_follow_up = selected;
      });
    },
    setUrgentCase: (urgent) => {
      set((state) => {
        state.form.is_urgent_case = urgent;
      });
    },
    setIsOtherDiseaseNoneSelected: (selected) => {
      set((state) => {
        state.isOtherDiseaseNoneSelected = selected;
        // reset all the other diseases if none is selected
        if (selected) {
          state.form.other_disease = DEFAULT_STATE.form.other_disease;
          state.isOtherCustomDiseaseSelected = false;
        }
      });
    },
    setIsOtherCustomDiseaseSelected: (selected) => {
      set((state) => {
        state.isOtherCustomDiseaseSelected = selected;
        state.isOtherDiseaseNoneSelected = false;
      });
    },
    setOtherDiseaseValue: (key, value) => {
      set((state) => {
        state.form.other_disease![key] = value;
        state.isOtherDiseaseNoneSelected = false;
      });
    },
    setCustomDiseaseValue: (value) => {
      set((state) => {
        state.form.other_disease!.custom = value;
        state.isOtherDiseaseNoneSelected = false;
      });
    },
    setOtherDiseaseFollowUp: (value) => {
      set((state) => {
        state.form.other_disease!.follow_up = value;
      });
    },
    setIsSavingGrade: (saving) => {
      set((state) => {
        state.isSavingGrade = saving;
      });
    },
    reset: () => {
      set((state) => {
        Object.assign(state, DEFAULT_STATE);
      });
    },
  }))
);

function assignLateralityToImages(img: { path: string; file_uri: string }) {
  if (img.path.includes('OD')) {
    return {
      ...img,
      lat: 'right' as const,
    };
  }
  if (img.path.includes('OS')) {
    return {
      ...img,
      lat: 'left' as const,
    };
  }

  return img;
}
