// GENERIC STRINGS
export const ERROR_MESSAGE_LOAD_FAILURE = 'Some data could not be loaded.';
export const ERROR_MESSAGE_TRY_AGAIN_EMAIL =
  'Please try again later or contact admin@vitazi.ai for assistance.';
export const ERROR_PDF_FILE_UPLOAD = 'The must be a PDF file.';
export const ERROR_FORM_INCOMPLETE_OR_INCORRECT = 'Please ensure the form is complete and correct.';

// REPORT STRINGS
export const ERROR_DOWNLOADING_REPORT = 'Report could not be downloaded at this time.';
export const ERROR_MESSAGE_REPORT_GENERATION = 'Report could not be generated.';

// CHANGE PASSWORD STRINGS
export const SUCCESS_PASSWORD_CHANGE =
  'Password successfully changed! Please wait while you are redirected to the login page.';
export const ERROR_RESETTING_PASSWORD = 'Resetting password failed.';
export const ERROR_PASSWORDS_DONT_MATCH =
  'Please ensure the provided passwords match and try again.';

// CASE STRINGS
export const ERROR_CREATING_CASE = 'There was an error creating the case.';
export const ERROR_FETCH_CASES = 'Could not load case list.';
export const ERROR_FETCH_CAMERAS = 'Could not load camera options.';
export const ERROR_FETCH_COUNTRIES = 'Could not load country selection options.';

// DASHBOARD STRINGS
export const ERROR_DASH_FETCH_USER_COUNT = 'Could not load user count.';
export const ERROR_DASH_FETCH_CASE_COUNT = 'Could not load case count.';
export const ERROR_DASH_FETCH_IMAGE_COUNT = 'Could not load image count.';
export const ERROR_DASH_FETCH_PATIENT_COUNT = 'Could not load patient count.';
export const ERROR_DASH_STAT_HISTORY = 'Could not load analytics information.';

// USER LIST
export const ERROR_FETCH_USERS = 'Could not load user list.';

// BILLING PAGE
export const ERROR_FETCH_BILLING = 'Could not load billing information.';

// LEGAL DOC STRINGS
export const ERROR_FETCH_LEGAL = 'Could not load legal documents.';
export const ERROR_LEGAL_DOCUMENT_DOWNLOAD = 'Could not download legal document.';

// LOGIN STRINGS
export const ERROR_INVALID_LOGIN_CREDENTIALS = 'Invalid username or password.';
export const ERROR_LOGIN_REQUEST = 'Login request could not be completed.';
export const ERROR_SENDING_RESET_PASSWORD_EMAIL = 'Error sending reset password email.';
export const SUCCESS_SENDING_RESET_PASSWORD_EMAIL =
  'Password reset; please check your email for instructions on how to reset your password';

// SIGN UP STRINGS
export const ERROR_SIGN_UP_REQUEST = 'Registration request could not be completed.';
export const ERROR_SIGN_UP_API_EMAIL_IN_USE = 'e-mail address already in active users';
export const ERROR_SIGN_UP_EMAIL_IN_USE =
  'That email address is already in use.  Please use a different email address or log into your existing account.  ';

// PROFILE STRINGS
export const ERROR_ADD_LICENSE = 'Could not add new license.';
export const ERROR_FETCH_PROFILE = 'Could not load profile information.';
export const ERROR_LICENSES_FETCH = 'Could not load licenses information.';
export const SUCCESS_LICENSE_REQUESTED = 'License request successfully submitted.';
export const ERROR_REQUESTING_LICENSE = 'Error requesting license.';

// GRADE CASES
export const ERROR_GET_GRADE_CASES = 'Could not load cases for grading.';
export const ERROR_GRADING_CASE = 'Error GRADING case.';
export const ERROR_MISSING_SIGNATURE = 'Please sign the form';
export const ERROR_MISSING_IMAGES_READABLE =
  'Please ensure all images are set to readable or unreadable';
export const SUCCESS_GRADING_CASE = 'Case signed successfully';

// CLINICS
export const ERROR_GET_CLINICS = 'Could not load clinics.';
export const ERROR_ELIMINATE_CLINICS = 'Could not eliminate clinic';
export const SUCCESS_ELIMINATE_CLINIC = 'Clinic eliminated successfully';

// SITES
export const ERROR_GET_SITES = 'Could not load sites.';
export const ERROR_ELIMINATE_SITE = 'Could not eliminate site';
export const SUCCESS_ELIMINATE_SITE = 'Site eliminated successfully';
