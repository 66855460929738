import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useUserProfile } from '@/states/user-profile';
import { toast_error, toast_success } from '@/libs/toast-wrappers';
import { devlog } from '@/libs/dev-logs';
import { setGradeAcknowledge } from '@/api/grade';

export function usePatchGradeAcknowledge() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const profile = useUserProfile(useShallow((s) => s.profile));

  const patchGradeAcknowledge = useCallback(
    async (gradeId: string, caseId: string) => {
      setIsLoading(true);
      try {
        await setGradeAcknowledge(gradeId, caseId, profile?.email ?? '');
        toast_success(t('cases-table-acknowledge-alert-success'));
      } catch (error) {
        devlog('Error acknowledging grade', error);
        toast_error(t('cases-table-acknowledge-alert-error'));
      } finally {
        setIsLoading(false);
      }
    },
    [profile.email, t]
  );

  return {
    isLoading,
    patchGradeAcknowledge,
  };
}
