import { RouterProvider } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

// navigation
import { router } from './router/root';
import { LoadingInitial } from './components/loading-initial';

export const App = () => {
  return (
    <>
      <RouterProvider router={router} fallbackElement={<LoadingInitial />} />
      <ToastContainer stacked />
    </>
  );
};
