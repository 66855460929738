import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { Button } from '@/components/form/button';

/**
 * This component should show the result of the grading process.
 */
export function GradeResult() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-[40vh]">
      <div className="p-6 border border-gray-100 rounded-lg shadow max-w-md w-full text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Process Finished</h1>
        <p className="text-gray-600 mb-6">
          You’ve finished the grade process. The results will be available here soon. Thank you for
          your patience!
        </p>
        <div className="flex items-center justify-center">
          <Button onClick={() => navigate(ROUTES.GRADING)}>Go back</Button>
        </div>
      </div>
    </div>
  );
}
